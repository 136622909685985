import { Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private tokenSubject = new BehaviorSubject<string | null>(null);

  private static isAuthenticated(token: string): boolean {
    const now = new Date();
    try {
      return token
        ? new Date(jwtDecode<{ exp: number }>(token).exp * 1000) > now
        : false;
    } catch (error) {
      return false;
    }
  }

  constructor() {
    this.tokenSubject.next(this.getToken());
  }

  public getToken(): string | null {
    return localStorage.getItem('token');
  }

  public setToken(token: string): void {
    localStorage.setItem('token', token);
    this.tokenSubject.next(token);
  }

  public logout(): void {
    localStorage.removeItem('token');
    this.tokenSubject.next(null);
  }

  public isAuthenticated(): Observable<boolean> {
    return this.tokenSubject.pipe(
      map((token) => TokenService.isAuthenticated(token))
    );
  }

  public isAuthenticatedSync(): boolean {
    return TokenService.isAuthenticated(this.getToken());
  }

  public getUserId(): string | null {
    const token = this.getToken();
    if (!token) {
      return null;
    }

    return jwtDecode(token)['id'];
  }
}
