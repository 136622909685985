<script
  src="https://api.lincx.com/load"
  data-zone-id="ow7pir"
  data-origin-id="6703tuk"
  data-category="personal"
  data-affsub=""
  data-s1=""
  data-s2=""
  data-s3=""
></script>
<div>
  <div class="header-logo">
    <div class="logo">
      <a href="https://app.oneparkfinancial.com/overview">
        <img
          src="../../../../assets/logo.png"
          alt="Applications | One Park Financial"
          class="w-48"
        />
      </a>
    </div>
  </div>

  <div class="green-background-wrapper">
    <div class="green-background"></div>
    <div class="thanks-header">
      <div
        class="
          font-bold
          lg:leading-tight lg:max-w-4xl lg:py-16 lg:text-4xl
          md:max-w-lg
          mx-auto
          p-4
          py-8
          text-center text-white text-xl
        "
      >
        {{ 'decline_page.thank_you' | translate }}
        <br class="hidden md:block" /><span class="text-green-100"
          >{{ 'decline_page.opf_customer' | translate }} </span
        >.
      </div>
    </div>
  </div>

  <div class="partners-wrapper">
    <div class="partners-container">
      <h1 class="font-bold text-lg lg:text-4xl">
        {{ 'decline_page.your_request_returned' | translate }}
      </h1>
      <hr class="my-4" />
      <div class="h-4"></div>
      <h2 class="font-bold">
        {{ 'decline_page.choose_one_or_more' | translate }}
        options
      </h2>
      <!--      <div class="h-8"></div>-->
      <div
        class="mx-auto partners-script-container"
        id="partners-script-container"
      ></div>
    </div>
  </div>
</div>
