<div class="card">
  <div class="card-body my-2">
    <div class="d-flex justify-content-between">
      <div class="left d-flex flex-row align-items-center">
        <i class="fa fa-file mx-4"></i>
        <div class="d-flex flex-column ">
          <div>{{ 'overview_page.bank_statement' | translate }}</div>
          <!-- <div>Month 1</div> -->
        </div>
      </div>
      <div class="right mx-2">
        <button class="btn btn-primary rounded-pill" [ngClass]="{ uploaded: isUploaded }">
          {{isUploaded ? 'Uploaded' : 'Requested'}}
        </button>
      </div>
    </div> 
  </div>
</div>
