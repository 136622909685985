import { Injectable } from '@angular/core';
import { DocuSignFilesUploadedModel } from '../models/web-hook-notifications/docusign-files-uploaded.model';
import { WebHookNotificationModel } from '../models/web-hook-notifications/web-hook-notification.model';
import { WebHookType } from '../models/web-hook-notifications/webhook-type.enum';
import { FilesService } from './files.service';
import { SignalRService } from './signal-r.service';
import { LeadService } from './lead.service';
import { Router } from '@angular/router';
import { reportsUrl } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class DocuSignIntegrationService {
  constructor(
    private signalRService: SignalRService,
    private filesService: FilesService,
    private leadService: LeadService,
    private router: Router
  ) {}

  public initSubscription(envelopeId: string): void {
    const channel = `docusign/processing-notification/${envelopeId}`;
    this.signalRService.subscribe(
      channel,
      this.subscriptionCallBack.bind(this)
    );
  }

  private async subscriptionCallBack(
    model: WebHookNotificationModel
  ): Promise<void> {
    switch (model.webHookType.toString()) {
      case WebHookType[WebHookType.DocuSignFilesUploadedEvent].toString():
        this.onCompleteDocusignFilesUploaded(model);
        break;

      case WebHookType[WebHookType.DocuSignSigningCompleteEvent].toString():
        await this.onCompleteDocusignStep();
        break;
    }
  }

  private async onCompleteDocusignStep(): Promise<void> {
    this.leadService.updateApplicationStepsState();
    await this.router.navigateByUrl(reportsUrl);
  }

  private onCompleteDocusignFilesUploaded(
    model: WebHookNotificationModel
  ): void {
    const typedData = JSON.parse(model.rawBody) as DocuSignFilesUploadedModel;
    this.filesService.addFiles(typedData.files);
  }
}
