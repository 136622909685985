import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormArray,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  maxOwnersOwnershipSum,
  minMandatoryOwnersOwnershipSum,
  minOwnerOwnership,
  maxOwnerOwnership,
  onlyNumbersRegex,
  maxPhoneLength,
  minPhoneLength,
  maxCityLength,
  maxAge,
  minAge,
  zipCodeLength,
  maxAddressLength,
} from 'src/app/constants/validations.const';
import { OwnerInfo } from 'src/app/models/owner-info.model';
import { LeadService } from 'src/app/services/lead.service';
import { NotificationService } from 'src/app/services/notifications-service';
import { PhoneAsyncValidator } from 'src/app/services/phone-async-validator';
import { Validators } from '@angular/forms';
import { OnSubmit } from '../on-submit.interface';
import { ErrorResponseType } from 'src/app/models/errors/api-error-response.model';
import * as moment from 'moment';
import { CompletedStepsService } from 'src/app/services/completed-steps.service';
import { applicationFlowUrl, docusignUrl } from '../../../constants';
import { ownersInfoFormErrorMessages } from './owners-info.formErrorMessages';
import { Validators as CustomValidators } from '../../../validators';
import { Subscription } from 'rxjs';
import statesData from '../../../../assets/states.json';
import { acceptOnlyNumbersLettersAndSpaceRegex } from '../constants/regex.const';

@Component({
  selector: 'app-owners-info',
  templateUrl: './owners-info.component.html',
  styleUrls: ['./owners-info.component.scss'],
})
export class OwnersInfoComponent implements OnInit, OnSubmit, OnDestroy {
  isSingleOwner$: boolean;
  ownersIds: number[];
  formErrorMessages = ownersInfoFormErrorMessages;
  submitted: boolean = false;

  states: string[] = statesData;
  form: FormGroup = this.fb.group(
    {
      owners: this.fb.array([]),
    },
    { updateOn: 'blur', validators: [] }
  );

  private requestStateSub: Subscription | null = null;

  constructor(
    private leadService: LeadService,
    private notificationService: NotificationService,
    private router: Router,
    private fb: FormBuilder,
    private completedSteps: CompletedStepsService
  ) {}

  ngOnInit(): void {
    this.isSingleOwner$ = true;

    this.requestStateSub = this.leadService.requestState.subscribe(
      async (state) => {
        if (this.leadService.isOwnersInfoCompleted && state.id) {
          await this.router.navigate([
            applicationFlowUrl,
            this.completedSteps.getFirstNotCompletedStep(),
          ]);
        }
      }
    );

    this.leadService.getOwners().subscribe((owners: OwnerInfo[]) => {
      for (const owner of owners) {
        this.ownersFormArray.push(this.getOwnerFormGroupItem(owner));
      }

      this.ownersIds = owners.map((el) => el.id);

      this.setMandatoryValue();

      const quantityOfOwners = this.ownersFormArray.controls.length;

      if (quantityOfOwners === 1) {
        this.isSingleOwner$ = true;
      }

      this.setDefaultOwnershipValues();
    });
  }

  ngOnDestroy(): void {
    if (this.requestStateSub) {
      this.requestStateSub.unsubscribe();
    }
  }

  get readonly(): boolean {
    return this.leadService.isDocuSignCompleted;
  }

  private get ownerInfo(): OwnerInfo[] {
    return this.ownersFormArrayControls.map((el) => el.value as OwnerInfo);
  }

  private getOwnershipSum(ownerInfo: OwnerInfo[]): number {
    return ownerInfo.reduce((result, el) => result + Number(el.ownership), 0);
  }

  private get sumOwnerShip(): number {
    return this.getOwnershipSum(this.ownerInfo);
  }

  private get sumMandatoryOwnerShip(): number {
    return this.getOwnershipSum(this.ownerInfo.filter((el) => el.isMandatory));
  }

  get ownersFormArray(): FormArray {
    return this.form.get('owners') as FormArray;
  }

  get ownersFormArrayControls(): AbstractControl[] {
    return this.ownersFormArray.controls;
  }

  setDefaultOwnershipValues(): void {
    const ownersFormControls = this.ownersFormArrayControls;
    const quantityOfOwners = ownersFormControls.length;

    switch (quantityOfOwners) {
      case 1:
        ownersFormControls[0].patchValue({ ownership: 100 });
        break;
      case 2:
        ownersFormControls[0].patchValue({ ownership: 51 });
        ownersFormControls[1].patchValue({ ownership: 49 });
        break;
      case 3:
        ownersFormControls[0].patchValue({ ownership: 60 });
        ownersFormControls[1].patchValue({ ownership: 20 });
        ownersFormControls[2].patchValue({ ownership: 20 });
        break;
      default:
        break;
    }
  }

  getOwnerFormGroupItem(ownerInfo: OwnerInfo): FormGroup {
    const item = this.fb.group({
      firstName: [
        ownerInfo.firstName,
        {
          validators: [
            Validators.required,
            Validators.pattern(acceptOnlyNumbersLettersAndSpaceRegex),
          ],
        },
      ],
      lastName: [
        ownerInfo.lastName,
        {
          validators: [
            Validators.required,
            Validators.pattern(acceptOnlyNumbersLettersAndSpaceRegex),
          ],
        },
      ],
      ownership: [
        ownerInfo.ownership,
        {
          validators: [
            Validators.required,
            Validators.min(minOwnerOwnership),
            Validators.max(maxOwnerOwnership),
          ],
        },
      ],
      jobTitle: ['', Validators.required],
      address: [
        '',
        [Validators.required, Validators.maxLength(maxAddressLength)],
      ],
      city: ['', [Validators.required, Validators.maxLength(maxCityLength)]],
      dateOfBirth: [
        moment(ownerInfo.dateOfBirth).format('MM/DD/YYYY'),
        [
          Validators.required,
          CustomValidators.minAgeRestriction(minAge),
          CustomValidators.maxAgeRestriction(maxAge),
        ],
      ],
      phoneNumber: [
        ownerInfo.phoneNumber,
        {
          updateOn: 'blur',
          validators: [
            Validators.required,
            Validators.pattern(onlyNumbersRegex),
            Validators.maxLength(maxPhoneLength),
            Validators.minLength(minPhoneLength),
          ],
          asyncValidators: [new PhoneAsyncValidator(this.leadService)],
        },
      ],
      state: [ownerInfo.state, Validators.required],
      zipCode: [
        ownerInfo.zipCode,
        [
          Validators.required,
          Validators.pattern(onlyNumbersRegex),
          CustomValidators.len(zipCodeLength),
        ],
      ],
      isMandatory: [ownerInfo.isMandatory],
      id: [ownerInfo.id],
    });

    item.get('ownership')?.valueChanges.subscribe(() => {
      this.setMandatoryValue();
    });

    return item;
  }

  submit(): void {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }

    if (this.sumMandatoryOwnerShip <= minMandatoryOwnersOwnershipSum) {
      this.notificationService.showError(
        ErrorResponseType.OWNERS_MANDATORY_OWNERSHIP_SUM_IS_NOT_VALID
      );
      return;
    }

    if (this.sumOwnerShip > maxOwnersOwnershipSum) {
      this.notificationService.showError(
        ErrorResponseType.OWNERS_OWNERSHIP_SUM_IS_NOT_VALID
      );
      return;
    }

    const owners = [];
    for (const ownerForm of this.ownersFormArrayControls) {
      owners.push({
        ...ownerForm.value,
        dateOfBirth: moment
          .utc(ownerForm.value.dateOfBirth, 'MM/DD/YYYY')
          .toISOString(),
      });
    }

    this.leadService.updateOwners(owners).subscribe(async () => {
      this.leadService.updateApplicationStepsState();
      await this.router.navigateByUrl(docusignUrl);
    });
  }

  private setMandatoryValue(): void {
    const sortedItems = this.ownersFormArrayControls
      .map((el, index) => ({
        index,
        ownership: el.get('ownership')?.value,
        isMandatory: el.get('isMandatory')?.value,
      }))
      .sort((x, y) => (x < y ? 1 : x > y ? -1 : 0));

    let minMandatoryIndex = -1;
    let sumOwnerShip: number = 0;

    for (let i = 0; i < sortedItems.length; i++) {
      sumOwnerShip += sortedItems[i].ownership;

      if (
        sumOwnerShip > minMandatoryOwnersOwnershipSum &&
        minMandatoryIndex < 0
      ) {
        minMandatoryIndex = i;
      }
    }

    if (sumOwnerShip <= minMandatoryOwnersOwnershipSum) {
      minMandatoryIndex = sortedItems
        .map((el) => el.ownership)
        .filter((el) => el).length;
    }

    for (let i = 0; i < sortedItems.length; i++) {
      const isMandatory = i <= minMandatoryIndex;
      this.ownersFormArrayControls[sortedItems[i].index].patchValue({
        isMandatory,
      });
    }
  }

  getClass(control: FormControl): string {
    if (
      (control.invalid && (control.dirty || control.touched)) ||
      (this.submitted && control.invalid)
    ) {
      return 'is-invalid';
    } else if (this.submitted || control.valid) {
      return 'is-valid';
    } else {
      return '';
    }
  }
}
