<div class="wrapper">
  <div class="picture-container">
    <a
      href="https://app.oneparkfinancial.com/article/american-small-business-gets-funded-2020"
    >
      <img
        src="../../../../assets/logo.png"
        alt="Applications | One Park Financial"
        width="200"
      />
    </a>
  </div>
  <div class="main-container">
    <div class="header">
      <h3>
        Sign in to view the status
        <br />
        or complete next steps on your application.
      </h3>
    </div>
    <div>
      <div class="form-container">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="fields-block">
            <div
              class="form-field wide"
              [ngClass]="{
                error: email.invalid && (email.dirty || email.touched)
              }"
            >
              <label for="email">Email address</label>
              <input
                class="email__input form-container__necessary"
                id="email"
                formControlName="email"
              />
              <app-field-errors
                [control]="email"
                [errorMessages]="formErrorMessages.email"
              ></app-field-errors>
            </div>

            <div
              class="form-field wide"
              [ngClass]="{
                error: password.invalid && (password.dirty || password.touched)
              }"
            >
              <label for="password">Password</label>
              <input
                class="form-container__necessary"
                id="password"
                type="password"
                formControlName="password"
              />
              <app-field-errors
                [control]="password"
                [errorMessages]="formErrorMessages.password"
              ></app-field-errors>
            </div>

            <div style="color: red">{{ errorMessage }}</div>
            <button class="submit-button" type="submit">Log In</button>
            <div class="under-button-text">
              <hr />
              <p class="leading-text">
                Looking to Start a New Application? Use our
                <!-- TODO: Add correct link -->
                <a href="">Registration Page</a>
                <br />
                To reset your password
                <!-- TODO: Add correct link -->
                <a href="">Click here</a>
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="footer">
      <p class="footer-text">
        {{ 'application_flow_component.info_safe_1' | translate }} <br />
        {{ 'application_flow_component.info_safe_2' | translate }}
      </p>
      <div class="links">
        <a href="https://www.oneparkfinancial.com/terms-of-use">{{
          'registration_form.terms_of_use' | translate
        }}</a>
        <a href="https://www.oneparkfinancial.com/privacy-policy">{{
          'registration_form.privacy_policy' | translate
        }}</a>
      </div>
    </div>
  </div>
</div>
