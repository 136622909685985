import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as Sentry from '@sentry/angular';
import { Span } from '@sentry/tracing';
import { TokenService } from './token-service';

export enum TransactionType {
  ApiCall = 'http.call'
}

@Injectable({
  providedIn: 'root',
})
export class MonitoringService {
  constructor(private tokenService: TokenService) {}

  public startSpan(op: TransactionType, description: string): Span {
    if (environment.monitoring.sentry.enabled) {
      const span = Sentry.startTransaction({ op, name: description });
      Sentry.getCurrentHub().configureScope(scope =>
      {
        const userId = this.tokenService.getUserId();
        if (userId)
        {
          if (scope.getUser()) {
            scope.getUser().id = userId;
          }
        }

        scope.setSpan(span);
      });
      return span;
    }
  }

  public endSpan(span: Span): void {
    if (environment.monitoring.sentry.enabled && !!span) {
      span.finish();
    }
  }

}
