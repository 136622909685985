import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FilesService } from '../../services/files.service';
import { LeadService } from '../../services/lead.service';
import { SignalRService } from '../../services/signal-r.service';
import { OnSubmit } from './on-submit.interface';
import { noContinuePages } from './constants';
import { applicationFlowUrl } from '../../constants';
import { Router } from '@angular/router';
import { ApplicationFlowStepsEnum } from './steps-config/application-flow-steps.enum';

@Component({
  selector: 'app-application-flow',
  templateUrl: './application-flow.component.html',
  styleUrls: ['./application-flow.component.scss'],
})
export class ApplicationFlowComponent implements OnInit {
  childForm: OnSubmit | undefined;
  isContinueButtonVisible: boolean;
  isManualUploadPage: boolean = false;
  flowId = localStorage.getItem('flowId');

  constructor(
    private signalRService: SignalRService,
    private leadService: LeadService,
    private filesService: FilesService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.isContinueButtonVisible = this.getContinueButtonVisibility();

    this.router.events.subscribe(() => {
      this.isContinueButtonVisible = this.getContinueButtonVisibility();
    });

    this.signalRService.startConnection();
    this.leadService.reloadRequestState().subscribe();

    this.leadService.requestState.subscribe((state) => {
      if (this.leadService.isLoggedIn && state.id) {
        const statesMapping = [
          {
            isCompleted: state.isGeneralInfoCompleted,
            url: ApplicationFlowStepsEnum.GeneralInfo,
          },
          {
            isCompleted: state.isBusinessInfoCompleted,
            url: ApplicationFlowStepsEnum.BusinessInfo,
          },
          {
            isCompleted: state.isOwnersInfoCompleted,
            url: ApplicationFlowStepsEnum.OwnersInfo,
          },
          {
            isCompleted: state.isDocuSignCompleted,
            url: ApplicationFlowStepsEnum.Docusign,
          },
          {
            isCompleted: state.isReportsCompleted,
            url: ApplicationFlowStepsEnum.Reports,
          },
        ];

        let firstNotCompletedStep = statesMapping.find((el) => !el.isCompleted);

        if (firstNotCompletedStep == null) {
          firstNotCompletedStep = statesMapping[statesMapping.length - 1];
        }

        this.leadService.isLoggedIn = false;

        this.router.navigate([applicationFlowUrl, firstNotCompletedStep.url]);
      }
    });

    this.filesService.loadFiles();
  }

  next(): void {
    this.childForm?.submit();
  }

  childActivated(childForm: OnSubmit): void {
    this.childForm = childForm;
    this.changeDetectorRef.detectChanges();
  }

  private getContinueButtonVisibility(): boolean {
    return noContinuePages.some((page) => location.href.includes(page));
  }
}
