export class RequestFileResponse {
  id!: number;
  fileName!: string;
  createdAt!: Date;
  updatedAt!: Date;
  fileType!: RequestFileResponseType;
}

export enum RequestFileResponseType {
  DocuSign = 'DocuSign',
  Finicity = 'Finicity',
  ManualUpload = 'ManualUpload',
  Unknown = 'Unknown',
}

export enum RequestDocumentResponseType {
  GovernmentId = 'GovernmentId',
  BankStatement1 = 'BankStatement1',
  BankStatement2 = 'BankStatement2',
  BankStatement3 = 'BankStatement3',
}
