import { Dictionary } from '../../../interfaces/common';
import { FormErrorsEnum } from '../../../enum/FormErrors.enum';
import { LocalizationKeysEnum } from '../../../enum/LocalizationKeys.enum';
import { phoneErrorMessagesDictionary } from '../../../constants/form-error-messages';

interface GeneralInfoFormErrorMessagesDictionary {
  phone: Dictionary;
  requestedAmount: Dictionary;
  purpose: Dictionary;
}

export const generalInfoFormErrorMessages: GeneralInfoFormErrorMessagesDictionary =
  {
    phone: {
      ...phoneErrorMessagesDictionary,
    },
    requestedAmount: {
      [FormErrorsEnum.Required]: LocalizationKeysEnum.RequestedAmountRequired,
      [FormErrorsEnum.Min]: LocalizationKeysEnum.Min1,
      [FormErrorsEnum.Max]: LocalizationKeysEnum.Max500000,
      [FormErrorsEnum.Pattern]: LocalizationKeysEnum.OnlyNumbersAllowed,
    },
    purpose: {
      [FormErrorsEnum.Required]: LocalizationKeysEnum.PurposeRequired,
      [FormErrorsEnum.MaxLength]: LocalizationKeysEnum.MaxLength255,
    },
  };
