import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { OnSubmit } from '../on-submit.interface';
import { LoadingService } from '../../../services/loading.service';
import { LeadService } from 'src/app/services/lead.service';
import {
  applicationFlowUrl,
  businessInfoUrl,
  maxPhoneLength,
  maxPurposeLength,
  maxRequestedAmount,
  minPhoneLength,
  minRequestedAmount,
  onlyNumbersRegex,
} from 'src/app/constants';
import { PhoneAsyncValidator } from 'src/app/services/phone-async-validator';
import { CompletedStepsService } from 'src/app/services/completed-steps.service';
import { generalInfoFormErrorMessages } from './general-info.formErrorMessages';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-general-info',
  templateUrl: './general-info.component.html',
  styleUrls: ['./general-info.component.scss'],
})
export class GeneralInfoComponent implements OnInit, OnSubmit, OnDestroy {
  submitted: boolean = false;
  isLoading = this.loadingService.isLoading();
  formErrorMessages = generalInfoFormErrorMessages;
  form: FormGroup = this.fb.group({
    phone: [
      '',
      {
        updateOn: 'blur',
        validators: [
          Validators.required,
          Validators.pattern(onlyNumbersRegex),
          Validators.maxLength(maxPhoneLength),
          Validators.minLength(minPhoneLength),
        ],
        asyncValidators: [new PhoneAsyncValidator(this.leadService)],
      },
    ],
    requestedAmount: [
      '',
      [
        Validators.required,
        Validators.min(minRequestedAmount),
        Validators.max(maxRequestedAmount),
        Validators.pattern(onlyNumbersRegex),
      ],
    ],
    isHomeBased: [false, Validators.required],
    isActiveBankruptcy: [false, Validators.required],
    isBusinessCheckingAccount: [true, Validators.required],
    isActiveLoan: [false, Validators.required],
    purpose: [
      '',
      [Validators.required, Validators.maxLength(maxPurposeLength)],
    ],
  });

  private requestStateSub: Subscription | null = null;

  get readonly(): boolean {
    return this.leadService.isDocuSignCompleted;
  }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private loadingService: LoadingService,
    private leadService: LeadService,
    private completedSteps: CompletedStepsService
  ) {}

  ngOnInit(): void {
    this.loadingService.startLoading();
    this.requestStateSub = this.leadService.requestState.subscribe((state) => {
      if (this.leadService.isGeneralInfoCompleted && state.id) {
        this.router.navigate([
          applicationFlowUrl,
          this.completedSteps.getFirstNotCompletedStep(),
        ]);
      }
    });

    this.leadService.getGeneralInfo().subscribe(
      (generalInfo) => {
        this.form.patchValue(generalInfo);
        this.loadingService.endLoading();
      },
      () => {
        this.loadingService.endLoading();
      }
    );
  }

  submit(): void {
    this.submitted = true;
    if (this.form.valid) {
      this.leadService.updateGeneralInfo(this.form.value).subscribe(() => {
        this.leadService.updateApplicationStepsState();
        this.router.navigateByUrl(businessInfoUrl);
      });
    }
  }

  get phone(): FormControl {
    return this.form.get('phone') as FormControl;
  }

  get requestedAmount(): FormControl {
    return this.form.get('requestedAmount') as FormControl;
  }

  get purpose(): FormControl {
    return this.form.get('purpose') as FormControl;
  }

  getClass(control: FormControl): string {
    if(control.invalid && (control.dirty || control.touched) || (this.submitted && control.invalid)) {
      return 'is-invalid';
    } else if (this.submitted || control.valid) {
      return 'is-valid';
    } else {
      return '';
    }
  }

  ngOnDestroy(): void {
    if (this.requestStateSub) {
      this.requestStateSub.unsubscribe();
    }
  }
}
