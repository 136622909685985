import { FormErrorsEnum } from '../../enum/FormErrors.enum';
import { Dictionary } from '../../interfaces/common';

interface ResetPasswordFormDictionary {
  newPassword: Dictionary;
}

export const resetPasswordFormErrorMessages: ResetPasswordFormDictionary = {
  newPassword: {
    [FormErrorsEnum.Pattern]:
      'Password must have numerical, uppercase and lowercase characters',
    [FormErrorsEnum.MinLength]: 'Field must be at least 8 characters long',
  },
};
