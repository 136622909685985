<div *ngIf="show" id="invalid-feedback" >
  <div *ngFor="let error of errors" class="d-flex align-items-center justify-content-start mt-1">
    <i class="fa fa-circle-exclamation fs-5 mx-2"></i>
    <div  class="d-block">{{error}}</div>
  </div>
</div>

<!-- <div *ngIf="show" class="alert alert-danger">
  <div *ngFor="let error of errors">{{ error }}</div>
</div> -->
