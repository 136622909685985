import { CanActivate, Router } from '@angular/router';
import { TokenService } from '../services/token-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { applicationFlowUrl } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class AnonymousGuard implements CanActivate {
  constructor(public auth: TokenService, public router: Router) {}
  canActivate(): Observable<boolean> {
    return this.auth.isAuthenticated().pipe(
      map((isAuthenticated) => {
        if (isAuthenticated) {
          this.router.navigate([applicationFlowUrl]);
          return false;
        }
        return true;
      })
    );
  }
}
