import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from '../../../services/analytics.service';

@Component({
  selector: 'app-in-collections',
  templateUrl: './in-collections.component.html',
  styleUrls: ['./in-collections.component.scss'],
})
export class InCollectionsComponent implements OnInit {
  phoneNumber = '800-768-3119';

  constructor(private googleAnalytics: AnalyticsService) {}

  ngOnInit(): void {
    this.googleAnalytics.eventEmitter(
      'Loading',
      'App Flows',
      'Collections/Default',
      'Testing',
      1
    );
  }
}
