import { Injectable } from '@angular/core';
import { TranslateLoader, TranslateService } from '@ngx-translate/core';
import { ErrorResponseType } from '../models/errors/api-error-response.model';
import { Observable, of, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import * as Sentry from '@sentry/angular';
import enTranslations from 'src/assets/i18n/en.json';

export const HttpLoaderFactory = (http: HttpClient): TranslateHttpLoader => new TranslateHttpLoader(http);

export class TranslateHttpLoader implements TranslateLoader {
  constructor(private http: HttpClient, public prefix: string = '/assets/i18n/', public suffix: string = '.json') {}

  /**
   * Gets the translations from the server
   */
  public getTranslation(lang: string): Observable<object> {
    // En is embedded as a default language
    if (lang === 'en') {
      return of(enTranslations);
    }

    // Other languages get loaded via http when needed
    return this.http.get(`${this.prefix}${lang}${this.suffix}`)
      .pipe(
        retry(2),
        catchError((error: any, caught: Observable<object>) => {
          if (error.error instanceof ErrorEvent || error.error instanceof ProgressEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
            return caught;
          } else {
            Sentry.captureException(error);
            return throwError(error);
          }
        }));
  }
}

@Injectable({
  providedIn: 'root',
})
export class LocalizationService {
  constructor(private translationService: TranslateService) {}
  getLocalizedErrorType(errorType: ErrorResponseType): string {
    const result = this.getValidationMessageTranslation(
      `validation_messages.${errorType}`
    );

    if (result == null) {
      console.warn(`Translation for '${errorType}' not found`);
      return this.getValidationMessageTranslation(
        ErrorResponseType.SOMETHING_WENT_WRONG
      );
    }

    return result;
  }

  getLocalizedMessage(message: string): string {
    console.warn(`Translation for '${message}' not prepared`);
    return message;
  }

  public getValidationMessageTranslation(key: string): string | null {
    const result = this.translationService.instant(key);

    if (result === key) {
      return null;
    }

    return result;
  }
}
