<div class="container-fluid container-xl">

  <h1 class="visually-hidden">General Information</h1>

  <div id="head-line" class="row text-center justify-content-center">
    <div class="col-12">
      <h4>
        {{ 'general_page_form.header_top' | translate }}
      </h4>
      <p>{{ 'general_page_form.header_bottom' | translate }}</p>
    </div>
  </div>

  <div
    class="row justify-content-center"
    [formGroup]="form"
  >
    <div class="col-12 col-lg-10">
      <hr class="mb-5" />

      <form class="row g-4 needs-validation" novalidate (ngSubmit)="submit()">
        <div class="col-12 col-md-6">
          <label for="phone1" class="form-label">{{'general_page_form.phone_number' | translate}}</label>
          <input
            class="form-control form-control-lg"
            id="phone1"
            formControlName="phone"
            type="tel"
            autocomplete="tel"
            mask="(000) 000 0000 0"
            [dropSpecialCharacters]="true"
            maxlength="16"
            [ngClass]="getClass(phone)"
          />
          <div *ngIf="phone.pending || phone.valid && (phone.dirty || phone.touched) || (submitted && phone.valid)" class="valid-feedback">
            Looks good!
          </div>
          <div *ngIf="phone.invalid && (phone.dirty || phone.touched) || (submitted && phone.invalid)" class="invalid-feeback">
            <app-field-errors
              [control]="phone"
              [errorMessages]="formErrorMessages.phone"
              [showErrors]="submitted"
            ></app-field-errors>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <label for="requestedAmount" class="form-label">
            {{'general_page_form.capital_info' | translate}}
          </label>
          <input
            class="form-control form-control-lg"
            id="requestedAmount"
            formControlName="requestedAmount"
            type="text"
            mask="separator"
            thousandSeparator=","
            separatorLimit="100000"
            [dropSpecialCharacters]="true"
            placeholder="$ USD"
            [ngClass]="getClass(requestedAmount)"
          />
          <div *ngIf="requestedAmount.valid && (requestedAmount.dirty || requestedAmount.touched) || (submitted && requestedAmount.valid)" class="valid-feedback">
            Looks good!
          </div>
          <div *ngIf="requestedAmount.invalid && (requestedAmount.dirty || requestedAmount.touched) || (requestedAmount && requestedAmount.invalid)" class="invalid-feeback">
            <app-field-errors
              [control]="requestedAmount"
              [errorMessages]="formErrorMessages.requestedAmount"
              [showErrors]="submitted"
            ></app-field-errors>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <div class="bg-white border border-2 p-3">
            <p>{{'general_page_form.business_check' | translate}}</p>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                id="isBusinessCheckingAccount"
                type="radio"
                formControlName="isBusinessCheckingAccount"
                [value]="true"
              />
              <label class="form-check-label" for="isBusinessCheckingAccount">{{ 'common.yes' | translate}}</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                id="bankAccountNo"
                type="radio"
                formControlName="isBusinessCheckingAccount"
                [value]="false"
              />
              <label class="form-check-label" for="bankAccountNo">{{ 'common.no' | translate}}</label>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <div class="bg-white border border-2 p-3">
            <p>{{'general_page_form.is_home_based' | translate}}</p>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                id="homeBaseYes"
                type="radio"
                formControlName="isHomeBased"
                [value]="true"
              />
              <label class="form-check-label" for="homeBaseYes">{{ 'common.yes' | translate}}</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                id="homeBaseNo"
                type="radio"
                formControlName="isHomeBased"
                [value]="false"
              />
              <label class="form-check-label" for="homeBaseNo">{{ 'common.no' | translate}}</label>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <div class="bg-white border border-2 p-3">
            <p>{{'general_page_form.current_loan' | translate}}</p>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                id="currentLoanYes"
                type="radio"
                formControlName="isActiveLoan"
                [value]="true"
              />
              <label class="form-check-label" for="currentLoanYes">{{ 'common.yes' | translate}}</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                id="currentLoanNo"
                type="radio"
                formControlName="isActiveLoan"
                [value]="false"
              />
              <label class="form-check-label" for="currentLoanNo">{{ 'common.no' | translate}}</label>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <div class="bg-white border border-2 p-3">
            <p>{{'general_page_form.bankruptcy_check' | translate}}</p>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                id="bankruptYes"
                type="radio"
                formControlName="isActiveBankruptcy"
                [value]="true"
              />
              <label class="form-check-label" for="bankruptYes">{{ 'common.yes' | translate}}</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                id="bankruptNo"
                formControlName="isActiveBankruptcy"
                [value]="false"
              />
              <label class="form-check-label" for="bankruptNo">{{ 'common.no' | translate}}</label>
            </div>
          </div>
        </div>

        <div class="col-12">
          <label for="purpose" class="form-label">
            {{'general_page_form.money_use' | translate}}
          </label>
          <textarea
            type="text"
            class="form-control form-control-lg"
            value=""
            id="purpose"
            formControlName="purpose"
            [ngClass]="getClass(purpose)"
            rows="3"
          ></textarea>
          <div class="text-xs using-description">
            {{ 'general_page_form.use_example' | translate }}
          </div>
          <div *ngIf="purpose.valid && (purpose.dirty || purpose.touched) || (submitted && purpose.valid)" class="valid-feedback">
            Looks good!
          </div>
          
          <div *ngIf="purpose.invalid && (purpose.dirty || purpose.touched) || (submitted && purpose.invalid)" class="invalid-feeback">
              <app-field-errors
              [control]="purpose"
              [errorMessages]="formErrorMessages.purpose"
              [showErrors]="submitted"
            ></app-field-errors>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- <div class="general-info-container">
  <div class="head-text">
    <h1>{{ 'general_page_form.header_top' | translate }}</h1>
    <h2 class="text-xs">{{ 'general_page_form.header_bottom' | translate }}</h2>
  </div>

  <div class="form-container" [formGroup]="form" (ngSubmit)="submit()">
    <form>
      <div class="fields-block">
        <div class="form-field">
          <label for="first-name" class="text-xs">{{
            'general_page_form.phone_number' | translate
          }}</label>
          <input
            class="form-container__necessary phone-input"
            id="first-name"
            formControlName="phone"
            type="tel"
            autocomplete="tel"
            [attr.disabled]="readonly ? true : null"
            mask="(000) 000 0000 0"
            [dropSpecialCharacters]="true"
            maxlength="16"
          />
          <app-field-errors
            [control]="phone"
            [errorMessages]="formErrorMessages.phone"
          ></app-field-errors>
        </div>

        <div class="form-field">
          <label for="requested-amount" class="text-xs">{{
            'general_page_form.capital_info' | translate
          }}</label>
          <input
            class="form-container__necessary"
            id="requested-amount"
            formControlName="requestedAmount"
            type="tel"
            autocomplete="tel"
            [attr.disabled]="readonly ? true : null"
            mask="separator"
            thousandSeparator=","
            separatorLimit="100000"
            [dropSpecialCharacters]="true"
            placeholder="$ USD"
          />
          <app-field-errors
            [control]="requestedAmount"
            [errorMessages]="formErrorMessages.requestedAmount"
          ></app-field-errors>
        </div>

        <div class="form-field">
          <div class="radio-button-group">
            <div>
              <label class="text-xs">{{
                'general_page_form.business_check' | translate
              }}</label>
            </div>
            <label class="text-xs radio-button__container"
              >{{ 'common.yes' | translate
              }}<input
                name="isBusinessCheckingAccount"
                type="radio"
                formControlName="isBusinessCheckingAccount"
                [value]="true"
                [attr.disabled]="readonly ? true : null"
              />
              <span class="checkmark"></span>
            </label>
            <label class="text-xs radio-button__container"
              >{{ 'common.no' | translate
              }}<input
                name="isBusinessCheckingAccount"
                type="radio"
                formControlName="isBusinessCheckingAccount"
                [value]="false"
                [attr.disabled]="readonly ? true : null"
              />
              <span class="checkmark"></span>
            </label>
          </div>
          <app-field-errors
            [control]="form.get('isBusinessCheckingAccount')"
            [showErrors]="submitted"
          ></app-field-errors>
        </div>

        <div class="form-field">
          <div class="radio-button-group">
            <div>
              <label class="text-xs">{{
                'general_page_form.is_home_based' | translate
              }}</label>
            </div>
            <label class="text-xs radio-button__container"
              >{{ 'common.yes' | translate
              }}<input
                name="isHomeBased"
                type="radio"
                formControlName="isHomeBased"
                [value]="true"
                [attr.disabled]="readonly ? true : null"
              />
              <span class="checkmark"></span>
            </label>
            <label class="text-xs radio-button__container"
              >{{ 'common.no' | translate
              }}<input
                name="isHomeBased"
                type="radio"
                formControlName="isHomeBased"
                [value]="false"
                [attr.disabled]="readonly ? true : null"
              />
              <span class="checkmark"></span>
            </label>
          </div>
          <app-field-errors
            [control]="form.get('isHomeBased')"
            [showErrors]="submitted"
          ></app-field-errors>
        </div>

        <div class="form-field">
          <div class="radio-button-group">
            <div>
              <label class="text-xs">{{
                'general_page_form.current_loan' | translate
              }}</label>
            </div>
            <label class="text-xs radio-button__container"
              >{{ 'common.yes' | translate
              }}<input
                name="isActiveLoan"
                type="radio"
                name="isActiveLoan"
                formControlName="isActiveLoan"
                [value]="true"
                [attr.disabled]="readonly ? true : null"
              />
              <span class="checkmark"></span>
            </label>
            <label class="text-xs radio-button__container"
              >{{ 'common.no' | translate
              }}<input
                name="isActiveLoan"
                type="radio"
                formControlName="isActiveLoan"
                [value]="false"
                [attr.disabled]="readonly ? true : null"
              />
              <span class="checkmark"></span>
            </label>
          </div>
          <app-field-errors
            [control]="form.get('isActiveLoan')"
            [showErrors]="submitted"
          ></app-field-errors>
        </div>

        <div class="form-field">
          <div class="radio-button-group">
            <div>
              <label class="text-xs">{{
                'general_page_form.bankruptcy_check' | translate
              }}</label>
            </div>
            <label class="text-xs radio-button__container"
              >{{ 'common.yes' | translate
              }}<input
                name="isActiveBankruptcy"
                type="radio"
                formControlName="isActiveBankruptcy"
                [value]="true"
                [attr.disabled]="readonly ? true : null"
              />
              <span class="checkmark"></span>
            </label>
            <label class="text-xs radio-button__container"
              >{{ 'common.no' | translate
              }}<input
                name="isActiveBankruptcy"
                type="radio"
                formControlName="isActiveBankruptcy"
                [value]="false"
                [attr.disabled]="readonly ? true : null"
              />
              <span class="checkmark"></span>
            </label>
          </div>
          <app-field-errors
            [control]="form.get('isActiveBankruptcy')"
            [showErrors]="submitted"
          ></app-field-errors>
        </div>

        <div class="form-field wide">
          <label for="purpose" class="text-xs">{{
            'general_page_form.money_use' | translate
          }}</label>
          <input
            id="purpose"
            formControlName="purpose"
            [attr.disabled]="readonly ? true : null"
          />
          <app-field-errors
            [control]="purpose"
            [errorMessages]="formErrorMessages.purpose"
          ></app-field-errors>
        </div>
      </div>

      <div class="text-xs using-description">
        {{ 'general_page_form.use_example' | translate }}
      </div>
    </form>
  </div>
</div> -->
