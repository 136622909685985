import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApplicationFlowComponent } from './components/application-flow/application-flow.component';
import { GeneralInfoComponent } from './components/application-flow/general-info/general-info.component';
import { BusinessInfoComponent } from './components/application-flow/business-info/business-info.component';
import { OwnersInfoComponent } from './components/application-flow/owners-info/owners-info.component';
import { AuthGuard } from './guards/auth.guard';
import { AnonymousGuard } from './guards/anonymous.guard';
import { ReportsComponent } from './components/application-flow/reports/reports.component';
import { DocusignPageComponent } from './components/application-flow/docusign-page/docusign-page.component';
import { LoginComponent } from './components/login/login.component';
import { StandardOrFinicityComponent } from './components/application-flow/reports/standard-or-finicity/standard-or-finicity.component';
import { FinicityFlowComponent } from './components/application-flow/reports/finicity-flow/finicity-flow.component';
import { StandardFlowComponent } from './components/application-flow/reports/standard-flow/standard-flow.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ResetPasswordMailComponent } from './components/reset-password-mail/reset-password-mail.component';
import { OverviewPageComponent } from './components/application-flow/overview-page/overview-page.component';
import {
  OverviewAppFlowComponent
} from './components/application-flow/overview-page/components/overview-app-flow/overview-app-flow.component';
import { DeclineComponent } from './components/decline/decline.component';
import { RequiredQueryParamsGuard } from './guards/required-query-params.guard.';
import {
  applicationFlowUrl,
  businessInfoUrl,
  docusignUrl,
  finicityFlowUrl,
  generalInfoUrl,
  loginPageUrl,
  overviewUrl,
  ownersInfoUrl,
  reportsUrl,
  selectFlowUrl,
  resetPasswordMailPageUrl,
  standardFlowUrl,
  resetPasswordPageUrl,
  declinePageUrl,
  toRouteUrl,
  affiliateQuestioneerFlowUrl,
  completeUrl,
  inCollectionsPageUrl,
  questioneerFlowWildcardUrl,
  unknownUrl,
} from './constants';
import { CompletePageComponent } from './components/application-flow/complete-page/complete-page.component';
import { InCollectionsComponent } from './components/application-flow/in-collections/in-collections.component';

const applicationFlowRoutes: Routes = [
  { path: '', redirectTo: toRouteUrl(generalInfoUrl), pathMatch: 'full' },
  // Temporary disable Password Create Page
  // { path: asRoute('user', component: UserInfoComponent, data: { step: 'user' } },
  {
    path: toRouteUrl(generalInfoUrl),
    component: GeneralInfoComponent,
    data: { step: 'general' },
  },
  {
    path: toRouteUrl(businessInfoUrl),
    component: BusinessInfoComponent,
    data: { step: 'business' },
  },
  {
    path: toRouteUrl(ownersInfoUrl),
    component: OwnersInfoComponent,
    data: { step: 'owners' },
  },
  {
    path: toRouteUrl(docusignUrl),
    component: DocusignPageComponent,
    data: { step: 'docusign' },
  },
  {
    path: toRouteUrl(overviewUrl),
    component: OverviewPageComponent,
  },
  {
    path: toRouteUrl(completeUrl),
    component: CompletePageComponent,
  },
  {
    path: toRouteUrl(reportsUrl),
    component: ReportsComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: toRouteUrl(selectFlowUrl),
      },
      {
        path: toRouteUrl(selectFlowUrl),
        component: StandardOrFinicityComponent,
      },
      {
        path: toRouteUrl(standardFlowUrl),
        component: StandardFlowComponent,
      },
      {
        path: toRouteUrl(finicityFlowUrl),
        component: FinicityFlowComponent,
      },
    ],
  },
];

const routes: Routes = [
  {
    // Until LeadCapture flow is implemented: redirect from the root to /application
    path: '',
    pathMatch: 'full',
    redirectTo: toRouteUrl(applicationFlowUrl, true),
  },
  {
    path: toRouteUrl(loginPageUrl),
    component: LoginComponent,
    canActivate: [AnonymousGuard],
  },
  {
    path: toRouteUrl(applicationFlowUrl),
    component: ApplicationFlowComponent,
    children: applicationFlowRoutes,
    canActivate: [AuthGuard],
  },
  {
    // Until LeadCapture flow is npt re-implemented: redirect from /lp/nt to /application
    path: toRouteUrl(questioneerFlowWildcardUrl, true),
    redirectTo: toRouteUrl(applicationFlowUrl, true),
  },
  // {
  //   path: toRouteUrl(questioneerFlowUrl, true),
  //   loadChildren: () =>
  //     import('./components/questioneer-flow/questioneer-flow.module').then(
  //       (x) => x.QuestioneerModuleModule
  //     ),
  // },
  {
    path: toRouteUrl(affiliateQuestioneerFlowUrl, true),
    loadChildren: () =>
      import('./components/questioneer-flow/questioneer-flow.module').then(
        (x) => x.QuestioneerModuleModule
      ),
  },
  {
    path: toRouteUrl(resetPasswordMailPageUrl),
    component: ResetPasswordMailComponent,
  },
  {
    path: toRouteUrl(resetPasswordPageUrl),
    component: ResetPasswordComponent,
    canActivate: [RequiredQueryParamsGuard],
    data: {
      requiredQueryParams: ['token'],
    },
  },
  {
    path: toRouteUrl(overviewUrl),
    component: OverviewAppFlowComponent,
  },
  {
    path: toRouteUrl(declinePageUrl),
    component: DeclineComponent,
  },
  {
    path: toRouteUrl(inCollectionsPageUrl),
    component: InCollectionsComponent,
  },
  {
    path: unknownUrl,
    pathMatch: 'full',
    redirectTo: toRouteUrl(applicationFlowUrl, true),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {
  // constructor(router: Router) {
  //   router.events
  //     .subscribe(console.log);
  // }
}
