<div class="wrapper">
  <div class="text-center">
    <h1>{{ 'overview_page.business_funding_app' | translate }}</h1>
    <h2>{{ 'overview_page.progress_overview' | translate }}</h2>
    <div>{{ 'overview_page.status_of_app' | translate }}</div>
    <h4 class="text-primary"> {{ 'overview_page.in_progress' | translate }}</h4>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-6 mt-2">
      <div class="start-timer">{{ 'overview_page.started' | translate }}</div>
      <hr class="p-0 m-0">
      <div class="thanks-text">
        {{ 'overview_page.we_believe_that' | translate }}
      </div>
      <ul class="mt-3">
        <li class="d-flex justify-content-start align-items-center">
          <app-status-circle [isCompleted]="leadService.isGeneralInfoCompleted" [isCurrentStep]="
                currentStep === ApplicationFlowStepsEnum.GeneralInfo
              "></app-status-circle>
          <div class="mx-3 my-1">
            <h3>{{ 'flow_steps_names.general' | translate }}</h3>
            <p>
              {{ 'overview_page.congrats' | translate }}
            </p>
          </div>
        </li>
        <li class="d-flex justify-content-start align-items-center">
          <app-status-circle [renderVerticalLine]="true" [isCompleted]="leadService.isBusinessInfoCompleted"
            [isCurrentStep]="
                currentStep === ApplicationFlowStepsEnum.BusinessInfo
              "></app-status-circle>
          <div class="mx-3 my-1">
            <h3>{{ 'flow_steps_names.business' | translate }}</h3>
            <p>{{ 'overview_page.basic_info' | translate }}</p>
          </div>
        </li>
        <li class="d-flex justify-content-start align-items-center">
          <app-status-circle [renderVerticalLine]="true" [isCurrentStep]="
                currentStep === ApplicationFlowStepsEnum.OwnersInfo
              " [isCompleted]="leadService.isOwnersInfoCompleted"></app-status-circle>
          <div class="mx-3 my-1">
            <h3>{{ 'flow_steps_names.owners' | translate }}</h3>
            <p>{{ 'overview_page.tell_us_more' | translate }}</p>
          </div>
        </li>
        <li class="d-flex justify-content-start align-items-center">
          <app-status-circle [renderVerticalLine]="true" [isCompleted]="leadService.isDocuSignCompleted"
            [isCurrentStep]="
                currentStep === ApplicationFlowStepsEnum.Docusign
              "></app-status-circle>
          <div class="mx-3 my-1">
            <h3>{{ 'overview_page.document_signing' | translate }}</h3>
            <p>{{ 'overview_page.funding_app_signature' | translate }}</p>
          </div>
        </li>
        <li class="d-flex justify-content-start align-items-center">
          <app-status-circle [renderVerticalLine]="true" [isCompleted]="leadService.isReportsCompleted" [isCurrentStep]="
                currentStep === ApplicationFlowStepsEnum.Reports
              "></app-status-circle>
          <div class="mx-3 my-1">
            <h3>{{ 'overview_page.statement_upload' | translate }}</h3>
            <p>{{ 'overview_page.please_continue' | translate }}</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="col-sm-12 col-md-6 mt-2">
      <div class="card m-3">
        <h3 class="mt-4 text-center">{{ 'overview_page.about_the_applicant' | translate }}</h3>
        <div class="card-body">
          <div class="applicant-status-container" [class.hidePasswordForm]="isShow">
            <div class="applicant-status" [formGroup]="aboutForm">
              <div class="mx-3 mb-3">
                <label for="first-name" class="form-label">{{ 'registration_form.first_name' | translate }}</label>
                <input class="form-control form-control-lg" id="first-name" formControlName="firstName" type="text" />
              </div>

              <div class="mx-3 mb-3">
                <label for="last-name" class="form-label">{{ 'registration_form.last_name' | translate }}</label>
                <input class="form-control form-control-lg" id="last-name" formControlName="lastName" type="text" />
              </div>

              <div class="mx-3 mb-3">
                <label for="phone" class="form-label">{{ 'registration_form.phone_number' | translate }}</label>
                <input class="form-control form-control-lg" id="phone" formControlName="phone" type="tel"
                  autocomplete="tel" mask="(000) 000 0000 0" [dropSpecialCharacters]="true" maxlength="16" />
                <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="invalid-feeback">
                  <div *ngIf="phone.errors?.required" class="d-block">{{validation-messages.phone_required | translate}}
                  </div>
                  <div *ngIf="phone.errors?.invalidPhone" class="d-block">
                    {{validation-messages.only_numbers_allowed_pattern | translate}}</div>
                  <div *ngIf="phone.errors?.maxlength" class="d-block">{{validation-messages.phone_incorrect | translate}}
                  </div>
                  <div *ngIf="phone.errors?.minlength" class="d-block">{{validation-messages.phone_max_length_15 |
                    translate}}</div>
                  <div *ngIf="phone.errors?.pattern" class="d-block">{{validation-messages.min_8 | translate}}</div>
                </div>
              </div>

              <div class="mx-3 mb-3">
                <label for="email" class="form-label">{{ 'registration_form.email_address' | translate }}</label>
                <input class="form-control form-control-lg" id="email" formControlName="email" type="text" />
              </div>
 
              <div class="row text-center">
                <div class="col-4">
                  <button class="btn btn-primary w-100 m-4">
                    {{ 'owners_page_form.save' | translate }}
                  </button>
                </div>
                <div class="col-8">
                  <div class="reset-password-wrapper mt-2">
                    <div class="reset-container">
                      <div class="change-password-text">
                        <!-- <p>{{ 'overview_page.change_password' | translate }}</p> -->
                        <p class="mt-4">{{ 'overview_page.profile_was_changed' | translate }}</p>
                      </div>
                      <!-- <div class="d-flex justify-content-center align-items-center" (click)="toggle()">
                        <span class="reset-password-button">{{ buttonName }}</span>
                        <i class="fa fa-arrow-down"></i>
                      </div>
                    </div>
                    <div *ngIf="isShow" class="reset-password-hidden">
                      <div>
                        <div class="password-input">
                          <label for="newPassword">{{
                            'overview_page.new_password' | translate
                            }}</label>
                          <input type="password" id="newPassword" [formControl]="newPassword" />
                        </div>
                        <div class="password-input">
                          <label for="confirmPassword">{{
                            'reset_password_page.confirm_password' | translate
                            }}</label>
                          <input type="password" [formControl]="confirmPassword" id="confirmPassword" />
                        </div>
                      </div>
                      <app-field-errors errorMessage="Passwords do not match." [showErrors]="showConfirmPasswordError">
                      </app-field-errors>
                      <button class="save-password" (click)="onResetPassword()">
                        {{ 'overview_page.save_new_password' | translate }}
                      </button>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <h1>{{ 'overview_page.documents' | translate }}</h1>
    <p>{{ 'overview_page.upload_your_documents' | translate }}</p>
    <div class="statements-container">
      <app-statement-info [documentType]="documentTypes.BankStatement1"></app-statement-info>
      <app-statement-info [documentType]="documentTypes.BankStatement2"></app-statement-info>
      <app-statement-info [documentType]="documentTypes.BankStatement3"></app-statement-info>
    </div>
  </div>
</div>