export const maxPurposeLength = 255;

export const minRequestedAmount = 1;
export const maxRequestedAmount = 500000;

export const minOwnerOwnership = 1;
export const maxOwnerOwnership = 100;

export const minMandatoryOwnersOwnershipSum = 50;
export const maxOwnersOwnershipSum = 100;

export const onlyNumbersRegex = /^[0-9]+$/;
export const onlyAlphabetsRegex = /^[A-Z]+$/i;
export const lowercaseAlphabetsRegex = /[a-z]/;
export const uppercaseAlphabetsRegex = /[A-Z]/;
export const numericsCharactersRegex = /\d/;

export const maxPhoneLength = 15;
export const minPhoneLength = 8;

export const maxNameLength = 30;

export const maxEmailLength = 200;

export const maxCompanyNameLength = 80;

export const maxMonthlyRevenueLength = 7;
export const maxMonthlyRevenueValue = 500000;

export const maxNameDbaLength = 50;

export const minYearValue = 1000;

export const feinLength = 9;

export const ownersNumberMinValue = 1;
export const ownersNumberMaxValue = 3;

export const minEmployeesNumber = 1;
export const maxEmployeesNumber = 999;

export const maxIndustryLength = 50;

export const maxAddressLength = 100;

export const maxCityLength = 25;

export const maxStateLength = maxCityLength;

export const zipCodeLength = 5;

export const dateOfBirthFormat = 'MM/DD/YYYY';

export const minAge = 18;
export const maxAge = 112;

export const minPasswordLength = 8;

export const dateOfBirthLength = 8;
