export enum PlatformAPI {
  ApplicationState = '/application/state',
  ApplicationApplicantContactInfo = '/application/applicant-contact-info',
  ApplicationGeneralInfo = '/application/general-info',
  ApplicationBusinessInfo = '/application/business-info',
  ApplicationBusinessOwners = '/application/business-owners',

  DocusignLink = '/docusign/link',
  DocusignState = '/docusign/state',

  FinicityLink = '/finicity/link',

  LookupPhoneNumber = '/lookup/phoneNumber/{phone}',
  UserIsExist = '/user/is-exist?email={email}',

  DocumentsUpload = '/documents/upload',
  DocumentsFiles = '/documents/files',
}

export enum PlatformAPIErrorMessages {
  OneOrMoreValidationErrors = 'One or more validation errors occurred',
  EmptyFile = 'Input file is empty',
  FileTooBig = 'File size should be less than 50mb. Please, select another one.',
  UnsupportedExtension = 'File extension is not supported',
}
