import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

export enum WelcomeBackPopupResult {
  LoginWithPassword,
  SendGeneratePasswordEmail,
}

@Component({
  selector: 'app-welcome-back-popup',
  templateUrl: './welcome-back-popup.component.html',
  styleUrls: ['./welcome-back-popup.component.scss'],
})
export class WelcomeBackPopupComponent {
  welcomeBackPopupResults = {
    loginWithPassword: WelcomeBackPopupResult.LoginWithPassword,
    sendGeneratePasswordEmail: WelcomeBackPopupResult.SendGeneratePasswordEmail,
  };

  constructor(private dialogRef: MatDialogRef<WelcomeBackPopupComponent>) {}

  onSubmit(popupResult: WelcomeBackPopupResult): void {
    this.dialogRef.close(popupResult);
  }
}
