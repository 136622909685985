import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-complete-page',
  templateUrl: './complete-page.component.html',
  styleUrls: ['./complete-page.component.scss'],
})
export class CompletePageComponent implements OnInit {
  flowId;
  smallDollar: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.flowId = localStorage.getItem('flowId');
    if(this.flowId === '10') {
      this.smallDollar = true;
    }
  }
}
