import { Component, OnDestroy, OnInit } from '@angular/core';
import { FinicityIntegrationService } from 'src/app/services/finicity-integration.service';
import { LeadService } from 'src/app/services/lead.service';
import { LoadingService } from 'src/app/services/loading.service';
import { environment } from '../../../../../environments/environment';
import { Router } from '@angular/router';
import {
  completeUrl,
  reportsUrl,
  selectFlowUrl,
  standardFlowUrl,
} from '../../../../constants';
import { AnalyticsService } from '../../../../services/analytics.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  FinicityErrorPopupComponent,
  FinicityErrorPopupPopupResult,
} from './finicity-error-popup/finicity-error-popup.component';
import { finalize } from 'rxjs/operators';
import { FilesService } from 'src/app/services/files.service';

@Component({
  selector: 'app-finicity-flow',
  templateUrl: './finicity-flow.component.html',
  styleUrls: ['./finicity-flow.component.scss'],
})
export class FinicityFlowComponent implements OnInit, OnDestroy {
  private events: any[] = [];
  private dialogRef: MatDialogRef<FinicityErrorPopupComponent, any>;

  constructor(
    private loadingService: LoadingService,
    private leadService: LeadService,
    private finicityIntegrationService: FinicityIntegrationService,
    private router: Router,
    private googleAnalytics: AnalyticsService,
    private dialog: MatDialog,
    private filesService: FilesService
  ) {}

  ngOnInit(): void {
    if (!environment.enableFinicity) {
      this.router.navigateByUrl(reportsUrl);
      return;
    }

    this.googleAnalytics.eventEmitter(
      'Loading',
      'App Flows',
      'Finicity',
      'Testing',
      1
    );

    this.finicityIntegrationService.error.subscribe((errorMessage) => {
      if (errorMessage) {
        this.onFinicityError();
      }
    });

    this.loadingService.startLoading();
    this.leadService.getFinicityLink().subscribe(
      (response) => {
        (window as any).finicityConnect.launch(response.link, {
          selector: '.finicity-container',
          overlay: 'rgba(255,255,255, 0)',
          success: (data: any) => {
            this.addEvent('success', data);
            this.filesService.setFinicityFilesMetadata();
          },
          cancel: (data: any) => {
            this.addEvent('cancel', data, 'The user cancelled the iframe');
          },
          error: (data: any) => {
            this.addEvent(
              'error',
              data,
              'Some runtime error was generated during insideConnect'
            );
          },
          loaded: (data) => {
            this.loadingService.endLoading();
            this.addEvent(
              'loaded',
              data,
              'This gets called only once after the iframe has finished loading'
            );
          },
          route: (data: any) => {
            this.addEvent(
              'route',
              data,
              'This is called as the user navigates through Connect'
            );
          },
          user: (data: any) => {
            this.addEvent(
              'user',
              data,
              'This is called as the user interacts with Connect'
            );
          },
        });

        this.finicityIntegrationService.initSubscription(
          response.customerId as string
        );
      },
      () => {
        this.loadingService.endLoading();
      }
    );
  }

  onFinicityError(): void {
    if (this.dialogRef) {
      return;
    }

    this.dialogRef = this.dialog.open(FinicityErrorPopupComponent, {
      panelClass: 'finicity-error-dialog-container',
    });

    this.dialogRef
      .afterClosed()
      .pipe(finalize(() => (this.dialogRef = undefined)))
      .subscribe((result: FinicityErrorPopupPopupResult) => {
        const urlToRedirect =
          result === FinicityErrorPopupPopupResult.RedirectToManualUploadPage
            ? standardFlowUrl
            : selectFlowUrl;
        this.router.navigateByUrl(urlToRedirect);
      });
  }

  addEvent(event: string, data: any, message: string | null = null): void {
    if (data?.data?.error) {
      this.onFinicityError();
    }
    this.events.push({
      event,
      data,
      message,
    });
  }

  ngOnDestroy(): void {
    (window as any).finicityConnect.destroy();
  }

  submit(): void {
    this.googleAnalytics.eventEmitter(
      'Completed',
      'App Flows',
      'Finicity',
      'Testing',
      1
    );
    this.router.navigateByUrl(completeUrl);
  }
}
