import { CanActivate, Router } from '@angular/router';
import { TokenService } from '../services/token-service';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { applicationFlowUrl } from '../constants';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(public tokenService: TokenService, public router: Router) {}
  canActivate(): Observable<boolean> {
    const queryParams = new URLSearchParams(window.location.search);
    const tokenParam = queryParams.get('token');
    const flowIdParam = queryParams.get('flowId');

    if (tokenParam) {
      this.tokenService.setToken(tokenParam);
      this.setFlowId(flowIdParam);
      this.router.navigate([applicationFlowUrl]);
      return of(true);
    }

    if (this.tokenService.isAuthenticatedSync()) {
      return of(true);
    }

    this.tokenService.logout();
    window.location.replace(environment.globalQuestionnaireFlowUrl);
    return of(false);
  }
  public setFlowId(flowId: string): void {
    localStorage.setItem('flowId', flowId);
  }
}
