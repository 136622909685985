import { OpfEnvironment, OpfEnvironmentName } from './models/opf-environment.module';

export const environment: OpfEnvironment = {
  production: true,
  apiBaseUrl: 'https://api.oneparkfinancial.com/v1',
  gtmTag: 'GTM-T382V8',
  useGtm: true,
  defaultLocale: '',
  locales: [],
  enableFinicity: true,
  apiToken: 'E67D1A51B59D6CBA128F22B2EE23C',
  globalQuestionnaireFlowUrl: 'https://app.oneparkfinancial.com/lp/nt',
  monitoring: {
    sentry: {
      enabled: true,
      dsn: 'https://93605f6d32bb4e729d332d070d6548d4@sentry.advance43.com/4',
      environment: OpfEnvironmentName.Prod,
      release: process.env.NG_APP_RELEASE_VERSION || 'unknown - prod',
      dist: process.env.NG_APP_ENV
    }
  }
};
