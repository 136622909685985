import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { TokenService } from '../services/token-service';
import { Observable } from 'rxjs';
import { API_BASE_URL } from 'src/app/services/base-api-service';
import { environment } from '../../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  baseUrl: string;
  constructor(
    public auth: TokenService,
    @Optional() @Inject(API_BASE_URL) baseUrl?: string
  ) {
    this.baseUrl = baseUrl != null ? baseUrl : '';
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authToken = this.auth.getToken();

    if (this.isApiCall(request)) {
      request = request.clone({
        setHeaders: {
          ApiToken: environment.apiToken,
        },
      });

      if (authToken != null) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${authToken}`,
          },
        });
      }
    }
    return next.handle(request);
  }

  isApiCall(request: HttpRequest<any>): boolean {
    return request.url.startsWith(this.baseUrl);
  }
}
