<div id="steps" class="row">
  <div class="col-12">
    <div
      class="
        steps
        my-4
        d-flex
        justify-content-evenly
        border border-1
        py-2 py-lg-3
      "
    >
      <!-- <button
        *ngFor="let step of activeSteps$ | async"
        routerLink="{{ step.url }}"
        type="button"
        class="step btn btn-link d-block text-decoration-none"
      > -->
      <div
        *ngFor="let step of activeSteps$ | async"
        class="step d-block"
      >
        <span
          *ngIf="!step.isCompleted"
          class="
            step
            status-incomplete
            border border-2 border-dark
            badge
            rounded-pill
            fs-6
            text-dark
          "
          >{{ step.stepNumber }}</span
        >

        <span
          *ngIf="step.isCompleted"
          class="
            step
            status-complete
            border border-2 border-primary
            badge
            rounded-pill
            fs-6
            text-white
            bg-primary
          "
        >
          <i class="fa-light fa-check"></i>
        </span>

        <p class="step-label d-none d-lg-block text-dark m-0">
          {{ step.stepName | translate }}
        </p>
      </div>
      <!-- </button> -->
    </div>
  </div>
</div>
