import { FormErrorsEnum } from '../enum/FormErrors.enum';
import { LocalizationKeysEnum } from '../enum/LocalizationKeys.enum';
import { Dictionary } from '../interfaces/common';

interface LoginFormErrorMessagesDictionary {
  email: Dictionary;
  password: Dictionary;
}

export const loginFormErrorMessages: LoginFormErrorMessagesDictionary = {
  email: {
    [FormErrorsEnum.Required]: LocalizationKeysEnum.EmailRequired,
    [FormErrorsEnum.Email]: LocalizationKeysEnum.EmailIncorrect,
  },
  password: {
    [FormErrorsEnum.Required]: LocalizationKeysEnum.PasswordRequired,
    [FormErrorsEnum.Password]: LocalizationKeysEnum.PasswordIncorrect,
  },
};
