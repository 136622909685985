import { Injectable } from '@angular/core';
import { Utm } from '../models/utm.model';
import { Params } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UtmService {
  private static readonly utmKey: string = 'utmQueryString';

  private static utmParameters: { [id: string]: string } = {
    utmLeadSource: 'utm_lead_source',
    utmCampaign: 'utm_campaign',
    utmSource: 'utm_source',
    utmMedium: 'utm_medium',
    utmTerm: 'utm_term',
    utmContent: 'utm_content',
    refererUrl: 'referer_url',
    gaClientId: 'ga_client_id',
    gclId: 'gclid',
    affSub: 'aff_sub',
    transactionId: 'transaction_id',
  };

  private getEmptyUtm(): Utm {
    return {
      utmLeadSource: null,
      utmCampaign: null,
      utmSource: null,
      utmMedium: null,
      utmTerm: null,
      utmContent: null,
      refererUrl: null,
      gaClientId: null,
      gclId: null,
      affSub: null,
      transactionId: null,
    };
  }

  storeUtm(queryParams: Params): void {
    const utm: Utm = this.getEmptyUtm();

    for (const key of Object.keys(UtmService.utmParameters)) {
      utm[key] = queryParams[UtmService.utmParameters[key]];
    }

    localStorage.setItem(UtmService.utmKey, JSON.stringify(utm));
  }

  getUtm(): Utm {
    const utmQueryString: string = localStorage.getItem(UtmService.utmKey);
    if (!utmQueryString) {
      return this.getEmptyUtm();
    }
    return JSON.parse(utmQueryString);
  }

  convertUtmToQuery(utm: Utm): string {
    let result = '';

    for (const objectKey of Object.keys(UtmService.utmParameters)) {
      const value = (utm as any)[objectKey] as string | null;
      if (value == null) {
        continue;
      }

      result += UtmService.utmParameters[objectKey] + '=' + value + '&';
    }

    return result;
  }

  clearUtm(): void {
    localStorage.removeItem(UtmService.utmKey);
  }
}
