import { Renderer2, Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  OPF_DEV_SCRIPT_PATH,
  OPF_QA_SCRIPT_PATH,
  OPF_SCRIPT_PATH,
  OPF_V2_DEV_SCRIPT_PATH,
  OPF_V2_QA_SCRIPT_PATH,
  OPF_V2_SCRIPT_PATH,
  ALZ_DEV_SCRIPT_PATH,
  ALZ_QA_SCRIPT_PATH,
  ALZ_SCRIPT_PATH
} from '../constants/design-scripts';

@Injectable({
  providedIn: 'root',
})
export class ScriptService {

  currentEnv: string;

  constructor(@Inject(DOCUMENT) private document: Document) { }

  /**
   * Append the JS tag to the Document Body.
   *
   * @param renderer The Angular Renderer
   * @param src The path to the script
   * @returns the script element
   */

  public loadJsScript(renderer: Renderer2, src: string): HTMLScriptElement {
    const script = renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    renderer.appendChild(this.document.head, script);
    return script;
  }

  public loadHeaderLink(renderer: Renderer2, src: string): HTMLScriptElement {
    const link = renderer.createElement('link');
    link.rel = 'stylesheet';
    link.href = src;
    renderer.appendChild(this.document.head, link);
    return link;
  }

  determineEnvironment(): void {
    const url = window.location.href;
    if(url.includes('-dev')) {
      this.currentEnv = 'DEV';
    } else if (url.includes('-qa')) {
      this.currentEnv = 'QA';
    } else {
      this.currentEnv = 'PROD';
    }
  }

  public getScriptBasedOnEnvironment(activeTheme: any): any {
    this.determineEnvironment();
    let script: any;

    switch(activeTheme.name) {
      // OPF THEME
      case '0':
        switch(this.currentEnv) {
          case 'DEV':
            script = OPF_DEV_SCRIPT_PATH;
            break;
          case 'QA':
            script = OPF_QA_SCRIPT_PATH;
            break;
          default:
            script = OPF_SCRIPT_PATH;
        }
        break;
      // OPF V2 THEME
      case '1':
        switch(this.currentEnv) {
          case 'DEV':
            script = OPF_V2_DEV_SCRIPT_PATH;
            break;
          case 'QA':
            script = OPF_V2_QA_SCRIPT_PATH;
            break;
          default:
            script = OPF_V2_SCRIPT_PATH;
        }
        break;
      // ALZ THEME
      case '2':
        switch(this.currentEnv) {
          case 'DEV':
            script = ALZ_DEV_SCRIPT_PATH;
            break;
          case 'QA':
            script = ALZ_QA_SCRIPT_PATH;
            break;
          default:
            script = ALZ_SCRIPT_PATH;
        }
    }
    return script;
  }
}
