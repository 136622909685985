import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LeadService } from 'src/app/services/lead.service';
import { DocuSignIntegrationService } from 'src/app/services/docusign-integration.service';
import { SigningDocumentState } from 'src/app/models/responses/signing-document.model';
import { FilesService } from 'src/app/services/files.service';
import {
  RequestFileResponse,
  RequestFileResponseType,
} from 'src/app/models/responses/request-file.model';
import { CompletedStepsService } from 'src/app/services/completed-steps.service';
import { applicationFlowUrl } from '../../../constants';
import { Subscription } from 'rxjs';
import { ApplicationFlowStepsEnum } from '../steps-config/application-flow-steps.enum';
import { HttpClient, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-docusign-page',
  templateUrl: './docusign-page.component.html',
  styleUrls: ['./docusign-page.component.scss'],
})
export class DocusignPageComponent implements OnInit, OnDestroy {
  url: string | null = null;
  redirectUrl: string | null = null;
  fileSigned: boolean = false;
  files: RequestFileResponse[] = [];
  isPageAvailable: boolean = false;

  private requestStateSub: Subscription | null = null;

  constructor(
    private router: Router,
    private http: HttpClient,
    private leadService: LeadService,
    private filesService: FilesService,
    private docuSignService: DocuSignIntegrationService,
    private completedSteps: CompletedStepsService
  ) {}

  ngOnDestroy(): void {
    if (this.requestStateSub) {
      this.requestStateSub.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.requestStateSub = this.leadService.requestState.subscribe(
      async (state) => {
        if (this.leadService.isGeneralInfoCompleted && state.id) {
          await this.router.navigate([
            applicationFlowUrl,
            this.completedSteps.getFirstNotCompletedStep(),
          ]);
        }
      }
    );

    this.filesService
      .getActualFiles(RequestFileResponseType.DocuSign)
      .subscribe((files) => {
        this.files = files;
      });

    this.leadService.requestState.subscribe((state) => {
      if (state.isDocuSignCompleted) {
        this.fileSigned = true;
      }

      this.isPageAvailable =
        state.isGeneralInfoCompleted &&
        state.isBusinessInfoCompleted &&
        state.isOwnersInfoCompleted;

      if (this.isPageAvailable && !this.fileSigned) {
        this.leadService.getSigningDocumentState().subscribe((document) => {
          // TODO: fix mapping enums from response
          if (
            document.documentState.toString() ===
            SigningDocumentState[SigningDocumentState.Init].toString()
          ) {
            this.leadService.getDocUsignLink().subscribe((result) => {
              this.url = result.url as string;
              this.redirectUrl = result.redirectUrl as string;
              this.docuSignService.initSubscription(result.envelopeId);
            });
          } else {
            this.fileSigned = true;
          }
        });
      }
    });
  }

  submit(): void {
    this.router.navigate([
      applicationFlowUrl,
      ApplicationFlowStepsEnum.Reports,
    ]);
  }

  @HostListener('window:message', ['$event'])
  onMessage(event: MessageEvent): void {
    const origin = event.origin || (event as any).originalEvent.origin;
    const data = event.data;
    switch (data) {
      case 'ESL:MESSAGE:REGISTER':
        event.source.postMessage('ESL:MESSAGE:ACTIVATE_EVENTS', origin);
        break;
      case 'ESL:MESSAGE:SUCCESS:SIGNER_COMPLETE':
        event.source.postMessage(data, origin);
        if (this.redirectUrl !== null) {
          const params = new HttpParams().set('event', 'signing_complete');
          this.http.get(this.redirectUrl, { params }).subscribe();
        }
        break;
      default:
        event.source.postMessage(data, origin);
        break;
    }
  }
}
