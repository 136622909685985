import { Component, OnInit } from '@angular/core';
import {
  RequestDocumentResponseType,
  RequestFileResponse,
  RequestFileResponseType,
} from 'src/app/models/responses/request-file.model';
import { FilesService } from 'src/app/services/files.service';
import { LeadService } from 'src/app/services/lead.service';
import { RequestState } from '../../../../models/request-state.model';
import { getStatementMonthDiff } from '../../../../utils/document-type.util';
import * as moment from 'moment';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ManualFilesUploadService } from 'src/app/services/manualFilesUpload.service';

@Component({
  selector: 'app-standard-flow',
  templateUrl: './standard-flow.component.html',
  styleUrls: ['./standard-flow.component.scss'],
})
export class StandardFlowComponent implements OnInit {
  isFinicityAllowed: boolean = false;
  finicityFiles$: Observable<RequestFileResponse[]>;
  requestDocumentResponseType = RequestDocumentResponseType;
  errorMessage: string;
  statementIsFinicity: boolean[] = [true, true, true];
  constructor(
    private leadService: LeadService,
    private filesService: FilesService,
    private manualFilesUploadService: ManualFilesUploadService
  ) {}

  public getDocumentMonth(documentType: RequestDocumentResponseType): string {
    const monthDiff: number = getStatementMonthDiff(documentType);
    const month = moment()
      .subtract(monthDiff, 'months')
      .format('MMMM')
      .toString()
      .toLowerCase();

    return month;
  }

  public getDocumentYear(documentType: RequestDocumentResponseType): string {
    const monthDiff: number = getStatementMonthDiff(documentType);
    const year = moment()
      .subtract(monthDiff, 'months')
      .format('YYYY')
      .toString();

    return year;
  }

  ngOnInit(): void {
    this.leadService.requestState.subscribe((state: RequestState) => {
      this.isFinicityAllowed =
        state.isGeneralInfoCompleted && state.isBusinessInfoCompleted;
    });

    this.finicityFiles$ = this.filesService.files.pipe(
      filter((files) =>
        files.some((f) => f.fileType === RequestFileResponseType.Finicity)
      )
    );

    this.manualFilesUploadService.errorMessage.subscribe((msg) => {
      this.errorMessage = msg;
    });

    this.filesService.files.subscribe((result: RequestFileResponse[]) => {
      const firstStatement = result.find(statement => statement.fileName.includes(RequestDocumentResponseType.BankStatement1));
      const secondStatement = result.find(statement => statement.fileName.includes(RequestDocumentResponseType.BankStatement2));
      const thirdStatement = result.find(statement => statement.fileName.includes(RequestDocumentResponseType.BankStatement3));
      this.statementIsFinicity = [(!firstStatement || firstStatement.fileType === RequestFileResponseType.Finicity),
        (!secondStatement || secondStatement.fileType === RequestFileResponseType.Finicity),
        (!thirdStatement || thirdStatement.fileType === RequestFileResponseType.Finicity)];
    });
  }
}
