import { FormErrorsEnum } from '../../../enum/FormErrors.enum';
import { LocalizationKeysEnum } from '../../../enum/LocalizationKeys.enum';
import { phoneErrorMessagesDictionary } from '../../../constants/form-error-messages';
import { Dictionary } from '../../../interfaces/common';

interface BusinessInfoFormErrorMessagesDictionary {
  nameDba: Dictionary;
  fein: Dictionary;
  phone: Dictionary;
  legalStructure: Dictionary;
  employeesNumber: Dictionary;
  industry: Dictionary;
  address: Dictionary;
  city: Dictionary;
  state: Dictionary;
  zipCode: Dictionary;
}

export const businessInfoFormErrorMessages: BusinessInfoFormErrorMessagesDictionary =
  {
    nameDba: {
      [FormErrorsEnum.Required]: LocalizationKeysEnum.BusinessNameRequired,
      [FormErrorsEnum.MaxLength]: LocalizationKeysEnum.MaxLength50,
      [FormErrorsEnum.Pattern]:
        LocalizationKeysEnum.OnlyLettersAndNumbersAllowed,
    },
    fein: {
      [FormErrorsEnum.Required]: LocalizationKeysEnum.FeinRequired,
      [FormErrorsEnum.IncorrectLength]:
        LocalizationKeysEnum.ShouldContain9Symbols,
      [FormErrorsEnum.Pattern]: LocalizationKeysEnum.OnlyNumbersAllowed,
    },
    phone: {
      ...phoneErrorMessagesDictionary,
      [FormErrorsEnum.Required]: LocalizationKeysEnum.BusinessPhoneRequired,
    },
    legalStructure: {
      [FormErrorsEnum.Required]: LocalizationKeysEnum.LegalStructureRequired,
    },
    employeesNumber: {
      [FormErrorsEnum.Required]: LocalizationKeysEnum.EmployeesNumberRequired,
      [FormErrorsEnum.Min]: LocalizationKeysEnum.Min1,
      [FormErrorsEnum.Max]: LocalizationKeysEnum.EmployeesMaxNumber,
    },
    industry: {
      [FormErrorsEnum.MaxLength]: LocalizationKeysEnum.MaxLength50,
      [FormErrorsEnum.Required]: LocalizationKeysEnum.IndustryRequired,
    },
    address: {
      [FormErrorsEnum.Required]: LocalizationKeysEnum.BusinessAddressRequired,
      [FormErrorsEnum.MaxLength]: LocalizationKeysEnum.MaxLength100,
    },
    city: {
      [FormErrorsEnum.Required]: LocalizationKeysEnum.CityRequired,
      [FormErrorsEnum.MaxLength]: LocalizationKeysEnum.MaxLength25,
    },
    state: {
      [FormErrorsEnum.Required]: LocalizationKeysEnum.StateRequired,
      [FormErrorsEnum.MaxLength]: LocalizationKeysEnum.MaxLength50,
    },
    zipCode: {
      [FormErrorsEnum.Required]: LocalizationKeysEnum.ZipRequired,
      [FormErrorsEnum.Pattern]: LocalizationKeysEnum.OnlyNumbersAllowed,
      [FormErrorsEnum.IncorrectLength]:
        LocalizationKeysEnum.ShouldContain5Symbols,
    },
  };
