<div class="wrapper">
  <div class="picture-container">
    <a
      href="https://app.oneparkfinancial.com/article/american-small-business-gets-funded-2020"
    >
      <img
        src="../../../../assets/logo.png"
        alt="Applications | One Park Financial"
        width="200"
      />
    </a>
  </div>

  <div class="header" *ngIf="!mailSent">
    <h1>{{ 'reset_password_mail.what_is_your_mail' | translate }}</h1>
    <h3>
      {{ 'reset_password_mail.well_send_you_link' | translate }}<br />
      {{ 'reset_password_mail.to_take_you_back' | translate }}<br />
      {{ 'reset_password_mail.right_where_you_left' | translate }}
    </h3>
  </div>

  <div class="header" *ngIf="mailSent">
    <h1>{{ 'reset_password_mail.we_sent_you_email' | translate }}</h1>
    <h3>
      {{ 'reset_password_mail.you_will_receive' | translate }}
      <br />({{ 'reset_password_mail.do_not_share_this_link' | translate }})
    </h3>
  </div>

  <div *ngIf="!mailSent">
    <div class="input__label">
      <label for="email">{{
        'registration_form.email_address' | translate
      }}</label>
      <input
        class="email__input form-container__necessary"
        id="email"
        [formControl]="email"
      />
    </div>
    <button (click)="onSendPasswordLink()">
      {{ 'reset_password_mail.send_password' | translate }}
    </button>
  </div>
  <div *ngIf="mailSent">
    <button routerLink="/lp/nt">
      {{ 'reset_password_page.go_to_main_page' | translate }}
    </button>
  </div>

  <div class="footer">
    <p class="footer-text">
      {{ 'application_flow_component.info_safe_1' | translate }} <br />
      {{ 'application_flow_component.info_safe_2' | translate }}
    </p>
    <div class="links">
      <a href="https://www.oneparkfinancial.com/terms-of-use">{{
        'registration_form.terms_of_use' | translate
      }}</a>
      <a href="https://www.oneparkfinancial.com/privacy-policy">{{
        'registration_form.privacy_policy' | translate
      }}</a>
    </div>
  </div>
</div>
