import { catchError, finalize, mergeMap } from 'rxjs/operators';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHeaders,
  HttpResponse,
  HttpResponseBase,
} from '@angular/common/http';
import { LoadingService } from './loading.service';
import { NotificationService } from './notifications-service';
import { ApiErrorResponse } from 'src/app/models/errors/api-error-response.model';
import { ErrorModel } from 'src/app/models/errors/error.model';
import { LocalizationService } from './localization.service';

export const API_BASE_URL = new InjectionToken<string>('API_BASE_URL');

function getTypedResponse<T>(response: HttpResponseBase): T {
  const typedResponse: HttpResponse<T> = response as HttpResponse<T>;
  return typedResponse.body as T;
}

@Injectable({
  providedIn: 'root',
})
export class BaseApiService {
  private http: HttpClient;
  private baseUrl: string;

  constructor(
    private loadingService: LoadingService,
    private notificationService: NotificationService,
    private localisationService: LocalizationService,
    @Inject(HttpClient) http: HttpClient,
    @Inject(API_BASE_URL) baseUrl?: string
  ) {
    this.http = http;
    this.baseUrl = baseUrl != null ? baseUrl : '';
  }

  public getData<T>(relativeUrl: string, useLoading: boolean, noThrow?: (response: HttpErrorResponse) => boolean): Observable<T> {
    if (useLoading) {
      this.startLoading();
    }

    return this.get<T>(relativeUrl).pipe(
      mergeMap((response: any) => this.processReturnResult<T>(response)),
      catchError((response: any) => this.processError<T>('get', response, noThrow)),
      finalize(() => {
        if (useLoading) {
          this.endLoading();
        }
      })
    );
  }

  public sendData<TModel, TResult>(
    method: string,
    relativeUrl: string,
    model: TModel,
    useLoading: boolean,
    noThrow?: (response: HttpErrorResponse) => boolean
  ): Observable<TResult> {
    if (useLoading) {
      this.startLoading();
    }

    return this.request<TModel, TResult>(method, relativeUrl, model).pipe(
      mergeMap((response: any) => this.processReturnResult<TResult>(response)),
      catchError((response: any) => this.processError<TResult>(method, response, noThrow)),
      finalize(() => {
        if (useLoading) {
          this.endLoading();
        }
      })
    );
  }

  public downloadFile(relativeUrl: string): Observable<Blob> {
    const url = (this.baseUrl + relativeUrl).replace(/[?&]$/, '');

    return this.http.get(url, {
      responseType: 'blob',
    });
  }

  private get<T>(
    relativeUrl: string,
    responseType: string = 'json'
  ): Observable<HttpEvent<T>> {
    const url = (this.baseUrl + relativeUrl).replace(/[?&]$/, '');
    const options: any = {
      observe: 'response',
      responseType,
      headers: new HttpHeaders({
        Accept: 'text/plain',
      }),
    };

    return this.http.request<T>('get', url, options);
  }

  private request<TModel, TResult>(
    method: string,
    relativeUrl: string,
    model: TModel
  ): Observable<HttpEvent<TResult>> {
    const url = (this.baseUrl + relativeUrl).replace(/[?&]$/, '');
    const content = this.getContent(model);
    const headers = this.getHeaders(model);

    const options: any = {
      body: content,
      observe: 'response',
      responseType: 'json',
      headers,
    };

    return this.http.request<TResult>(method, url, options);
  }

  private processError<T>(method: string, response: HttpErrorResponse, noThrow?: (response: HttpErrorResponse) => boolean): Observable<T> {
    if (response.status === 0) {
      // Connection lost, network error, the request is aborted
      return EMPTY;
    }

    const errorBody = response.error;
    const result = new ErrorModel();
    if (errorBody != null) {
      const errorObject: ApiErrorResponse = errorBody as ApiErrorResponse;
      result.message = errorObject.errorType
        ? this.localisationService.getLocalizedErrorType(errorObject.errorType)
        : this.localisationService.getLocalizedMessage(errorObject.message);
      this.notificationService.showErrorResponse(errorObject);

      // console.log(errorObject);
    } else {
      console.log(response);
    }

    result.name = response.name;
    result.message = `${method.toUpperCase()} ${response.url},`
      + ` result: ${response.status} ${response.statusText}, message: ${result.message}`;

    // to silent unwanted errors
    if (noThrow && noThrow(response)) {
      return EMPTY;
    }

    return throwError(result);

  }

  private processReturnResult<T>(response: HttpResponseBase): Observable<T> {
    const status = response.status;

    if (status >= 200 && status < 300) {
      const result = getTypedResponse<T>(response);
      return of(result);
    }

    return throwError({ message: 'Server returned unexpected result' });
  }

  private getContent<T>(model: T): any {
    const isFormData = model instanceof FormData;
    const content = isFormData ? model : JSON.stringify(model);

    return content;
  }

  private getHeaders<T>(model: T): HttpHeaders {
    let result = new HttpHeaders({
      Accept: 'application/json',
    });

    if (!(model instanceof FormData)) {
      result = result.append('Content-Type', 'application/json; charset=utf-8');
    }

    return result;
  }

  private startLoading(): void {
    this.loadingService.startLoading();
  }

  private endLoading(): void {
    this.loadingService.endLoading();
  }
}
