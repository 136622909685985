export enum ApplicationFlowStepsEnum {
  GeneralInfo = 'overall',
  BusinessInfo = 'business',
  OwnersInfo = 'owners',
  Docusign = 'docusign',
  Reports = 'reports',
  Finicity = 'finicity',
  Overview = 'overview',
  Complete = 'complete',
}
