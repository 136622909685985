<div class="main-container">
  <h3>Welcome back to One Park Financial!</h3>
  <h4>
    It looks like you have an account registered in our system. <br />
    We can send an email to get you back to where you left off.
  </h4>
  <div class="submit-container">
    <button
      class="sent-email-button"
      type="submit"
      name="email-btn"
      (click)="onSubmit(welcomeBackPopupResults.sendGeneratePasswordEmail)"
    >
      Send me an email
    </button>
    <a
      class="login-button"
      type="submit"
      name="login-btn"
      (click)="onSubmit(welcomeBackPopupResults.loginWithPassword)"
    >
      Log in using a password
    </a>
  </div>
</div>
