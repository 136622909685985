export interface SigningDocument {
  id: number;
  envelopeId: string;
  documentState: SigningDocumentState;
}

export enum SigningDocumentState {
  Init = 'Init',
  InProgress = 'InProgress',
  Downloaded = 'Downloaded',
  Error = 'Error',
}
