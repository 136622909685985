<div
  class="owners-wrapper"
  [class.count3]="
    ownersFormArrayControls && ownersFormArrayControls.length === 3
  "
>

  <h1 class="visually-hidden">Owner's Information</h1>

  <div class="container-fluid container-xl">
    <div id="head-line" class="row text-center justify-content-center">
      <div class="col-12">
        <h4>{{ 'owners_page_form.header_one' | translate }}</h4>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-12">
        <div [formGroup]="form" class="mt-5">
          <form
            class="row g-4 needs-validation"
            novalidate
            (ngSubmit)="submit()"
          >
            <div class="row">
              <div class="col-12">
                <div [formGroup]="form">
                  <div class="row justify-content-between">
                    <div
                      [ngClass]="{
                        'col-12': ownersFormArrayControls.length === 1,
                        'col-12 col-md-6 px-2':
                          ownersFormArrayControls.length === 2,
                        'col-12 col-lg-4 px-2 mb-3':
                          ownersFormArrayControls.length === 3
                      }"
                      formArrayName="owners"
                      *ngFor="
                        let item of ownersFormArrayControls;
                        let i = index
                      "
                    >
                      <div [formGroupName]="i">
                        <div
                          class="card"
                        >
                          <div class="card-body">
                            <h5>Owner #{{ i + 1 }}</h5>
                            <form
                              class="row g-4 needs-validation"
                              novalidate
                              (ngSubmit)="submit()"
                            >
                              <div class="col-12 col-md-6">
                                <label for="firstName{{i}}" class="form-label">{{
                                  'owners_page_form.first_name' | translate
                                }}</label>
                                <input
                                  type="text"
                                  class="form-control form-control-lg"
                                  id="firstName{{i}}"
                                  value=""
                                  formControlName="firstName"
                                  required
                                  [ngClass]="getClass(item.get('firstName'))"
                                />
                                <div
                                  *ngIf="
                                    (item.get('firstName').valid &&
                                      (item.get('firstName').dirty ||
                                        item.get('firstName').touched)) ||
                                    (submitted && item.get('firstName').valid)
                                  "
                                  class="valid-feedback"
                                >
                                  Looks good!
                                </div>
                                <div
                                  *ngIf="
                                    (item.get('firstName').invalid &&
                                      (item.get('firstName').dirty ||
                                        item.get('firstName').touched)) ||
                                    (submitted && item.get('firstName').invalid)
                                  "
                                  class="invalid-feeback"
                                >
                                  <app-field-errors
                                    [control]="item.get('firstName')"
                                    [errorMessages]="
                                      formErrorMessages.firstName
                                    "
                                    [showErrors]="submitted"
                                  ></app-field-errors>
                                </div>
                              </div>

                              <div class="col-12 col-md-6">
                                <label for="lastName{{i}}" class="form-label">{{
                                  'owners_page_form.last_name' | translate
                                }}</label>
                                <input
                                  type="text"
                                  class="form-control form-control-lg"
                                  id="lastName{{i}}"
                                  formControlName="lastName"
                                  value=""
                                  required
                                  [ngClass]="getClass(item.get('lastName'))"
                                />
                                <div
                                  *ngIf="
                                    (item.get('lastName').valid &&
                                      (item.get('lastName').dirty ||
                                        item.get('lastName').touched)) ||
                                    (submitted && item.get('lastName').valid)
                                  "
                                  class="valid-feedback"
                                >
                                  Looks good!
                                </div>
                                <div
                                  *ngIf="
                                    (item.get('lastName').invalid &&
                                      (item.get('lastName').dirty ||
                                        item.get('lastName').touched)) ||
                                    (submitted && item.get('lastName').invalid)
                                  "
                                  class="invalid-feeback"
                                >
                                  <app-field-errors
                                    [control]="item.get('lastName')"
                                    [errorMessages]="formErrorMessages.lastName"
                                    [showErrors]="submitted"
                                  ></app-field-errors>
                                </div>
                              </div>

                              <div class="col-12 col-md-6">
                                <label for="ownership{{i}}" class="form-label">{{
                                  'owners_page_form.ownership' | translate
                                }}</label>
                                <input
                                  class="form-control form-control-lg"
                                  id="ownership{{i}}"
                                  value=""
                                  type="tel"
                                  formControlName="ownership"
                                  placeholder="%"
                                  suffix="%"
                                  [dropSpecialCharacters]="true"
                                  mask="000"
                                  required
                                  [ngClass]="getClass(item.get('ownership'))"
                                />
                                <div
                                  *ngIf="
                                    (item.get('ownership').valid &&
                                      (item.get('ownership').dirty ||
                                        item.get('ownership').touched)) ||
                                    (submitted && item.get('ownership').valid)
                                  "
                                  class="valid-feedback"
                                >
                                  Looks good!
                                </div>
                                <div
                                  *ngIf="
                                    (item.get('ownership').invalid &&
                                      (item.get('ownership').dirty ||
                                        item.get('ownership').touched)) ||
                                    (submitted && item.get('ownership').invalid)
                                  "
                                  class="invalid-feeback"
                                >
                                  <app-field-errors
                                    [control]="item.get('ownership')"
                                    [errorMessages]="
                                      formErrorMessages.ownership
                                    "
                                    [showErrors]="submitted"
                                  ></app-field-errors>
                                </div>
                              </div>

                              <div class="col-12 col-md-6">
                                <label for="jobTitle{{i}}" class="form-label">{{
                                  'owners_page_form.job_title' | translate
                                }}</label>
                                <input
                                  type="text"
                                  class="form-control form-control-lg"
                                  id="jobTitle{{i}}"
                                  value=""
                                  formControlName="jobTitle"
                                  required
                                  [ngClass]="getClass(item.get('jobTitle'))"
                                />
                                <div
                                  *ngIf="
                                    (item.get('jobTitle').valid &&
                                      (item.get('jobTitle').dirty ||
                                        item.get('jobTitle').touched)) ||
                                    (submitted && item.get('jobTitle').valid)
                                  "
                                  class="valid-feedback"
                                >
                                  Looks good!
                                </div>
                                <div
                                  *ngIf="
                                    (item.get('jobTitle').invalid &&
                                      (item.get('jobTitle').dirty ||
                                        item.get('jobTitle').touched)) ||
                                    (submitted && item.get('jobTitle').invalid)
                                  "
                                  class="invalid-feeback"
                                >
                                  <app-field-errors
                                    [control]="item.get('jobTitle')"
                                    [errorMessages]="formErrorMessages.jobTitle"
                                    [showErrors]="submitted"
                                  ></app-field-errors>
                                </div>
                              </div>

                              <div class="col-12 col-md-6">
                                <label for="phone1{{i}}" class="form-label">{{
                                  'owners_page_form.phone_number' | translate
                                }}</label>
                                <input
                                  type="tel"
                                  class="form-control form-control-lg"
                                  id="phone1{{i}}"
                                  value=""
                                  formControlName="phoneNumber"
                                  autocomplete="tel"
                                  mask="(000) 000 0000 0"
                                  [dropSpecialCharacters]="true"
                                  maxlength="16"
                                  required
                                  [ngClass]="getClass(item.get('phoneNumber'))"
                                />
                                <div
                                  *ngIf="
                                    (item.get('phoneNumber').valid &&
                                      (item.get('phoneNumber').dirty ||
                                        item.get('phoneNumber').touched)) ||
                                    (submitted && item.get('phoneNumber').valid)
                                  "
                                  class="valid-feedback"
                                >
                                  Looks good!
                                </div>
                                <div
                                  *ngIf="
                                    (item.get('phoneNumber').invalid &&
                                      (item.get('phoneNumber').dirty ||
                                        item.get('phoneNumber').touched)) ||
                                    (submitted &&
                                      item.get('phoneNumber').invalid)
                                  "
                                  class="invalid-feeback"
                                >
                                  <app-field-errors
                                    [control]="item.get('phoneNumber')"
                                    [errorMessages]="formErrorMessages.phone"
                                    [showErrors]="submitted"
                                  ></app-field-errors>
                                </div>
                              </div>

                              <div class="col-12 col-md-6">
                                <label for="dob{{i}}" class="form-label">{{
                                  'owners_page_form.date_of_birth' | translate
                                }}</label>
                                <input
                                  type="tel"
                                  class="form-control form-control-lg"
                                  id="dob{{i}}"
                                  value=""
                                  formControlName="dateOfBirth"
                                  mask="M0/d0/0000"
                                  placeholder="MM/DD/YYYY"
                                  [dropSpecialCharacters]="false"
                                  required
                                  [ngClass]="getClass(item.get('dateOfBirth'))"
                                />
                                <div
                                  *ngIf="
                                    (item.get('dateOfBirth').valid &&
                                      (item.get('dateOfBirth').dirty ||
                                        item.get('dateOfBirth').touched)) ||
                                    (submitted && item.get('dateOfBirth').valid)
                                  "
                                  class="valid-feedback"
                                >
                                  Looks good!
                                </div>
                                <div
                                  *ngIf="
                                    (item.get('dateOfBirth').invalid &&
                                      (item.get('dateOfBirth').dirty ||
                                        item.get('dateOfBirth').touched)) ||
                                    (submitted &&
                                      item.get('dateOfBirth').invalid)
                                  "
                                  class="invalid-feeback"
                                >
                                  <app-field-errors
                                    [control]="item.get('dateOfBirth')"
                                    [errorMessages]="
                                      formErrorMessages.dateOfBirth
                                    "
                                    [showErrors]="submitted"
                                  ></app-field-errors>
                                </div>
                              </div>

                              <div class="col-12">
                                <label for="address{{i}}" class="form-label">{{
                                  'owners_page_form.address' | translate
                                }}</label>
                                <input
                                  type="text"
                                  class="form-control form-control-lg"
                                  id="address{{i}}"
                                  value=""
                                  formControlName="address"
                                  required
                                  [ngClass]="getClass(item.get('address'))"
                                />
                                <div
                                  *ngIf="
                                    (item.get('address').valid &&
                                      (item.get('address').dirty ||
                                        item.get('address').touched)) ||
                                    (submitted && item.get('address').valid)
                                  "
                                  class="valid-feedback"
                                >
                                  Looks good!
                                </div>
                                <div
                                  *ngIf="
                                    (item.get('address').invalid &&
                                      (item.get('address').dirty ||
                                        item.get('address').touched)) ||
                                    (submitted && item.get('address').invalid)
                                  "
                                  class="invalid-feeback"
                                >
                                  <app-field-errors
                                    [control]="item.get('address')"
                                    [errorMessages]="formErrorMessages.address"
                                    [showErrors]="submitted"
                                  ></app-field-errors>
                                </div>
                              </div>

                              <div class="col-12 col-md-4">
                                <label for="city{{i}}" class="form-label">{{
                                  'owners_page_form.city' | translate
                                }}</label>
                                <input
                                  type="text"
                                  class="form-control form-control-lg"
                                  id="city{{i}}"
                                  value=""
                                  formControlName="city"
                                  required
                                  [ngClass]="getClass(item.get('city'))"
                                />
                                <div
                                  *ngIf="
                                    (item.get('city').valid &&
                                      (item.get('city').dirty ||
                                        item.get('city').touched)) ||
                                    (submitted && item.get('city').valid)
                                  "
                                  class="valid-feedback"
                                >
                                  Looks good!
                                </div>
                                <div
                                  *ngIf="
                                    (item.get('city').invalid &&
                                      (item.get('city').dirty ||
                                        item.get('city').touched)) ||
                                    (submitted && item.get('city').invalid)
                                  "
                                  class="invalid-feeback"
                                >
                                  <app-field-errors
                                    [control]="item.get('city')"
                                    [errorMessages]="formErrorMessages.city"
                                    [showErrors]="submitted"
                                  ></app-field-errors>
                                </div>
                              </div>

                              <div class="col-12 col-md-4">
                                <label for="state{{i}}" class="form-label">{{
                                  'owners_page_form.state' | translate
                                }}</label>
                                <select
                                  id="state{{i}}"
                                  class="form-select form-select-lg"
                                  formControlName="state"
                                  autocomplete="shipping region"
                                >
                                  <option [ngValue]="null" disabled selected>
                                    {{
                                      'validation_messages.select_a_state'
                                        | translate
                                    }}
                                  </option>
                                  <option
                                    *ngFor="let state of states"
                                    [ngValue]="state"
                                  >
                                    {{ state }}
                                  </option>
                                </select>
                                <div
                                  *ngIf="
                                    (item.get('state').valid &&
                                      (item.get('state').dirty ||
                                        item.get('state').touched)) ||
                                    (submitted && item.get('state').valid)
                                  "
                                  class="valid-feedback"
                                >
                                  Looks good!
                                </div>
                                <div
                                  *ngIf="
                                    (item.get('state').invalid &&
                                      (item.get('state').dirty ||
                                        item.get('state').touched)) ||
                                    (submitted && item.get('state').invalid)
                                  "
                                  class="invalid-feeback"
                                >
                                  <app-field-errors
                                    [control]="item.get('state')"
                                    [errorMessages]="formErrorMessages.state"
                                    [showErrors]="submitted"
                                  ></app-field-errors>
                                </div>
                              </div>

                              <div class="col-12 col-md-4">
                                <label for="zipCode{{i}}" class="form-label">{{
                                  'owners_page_form.zip_code' | translate
                                }}</label>
                                <input
                                  type="tel"
                                  class="form-control form-control-lg"
                                  id="zipCode{{i}}"
                                  value=""
                                  formControlName="zipCode"
                                  mask="00000"
                                  required
                                  [ngClass]="getClass(item.get('zipCode'))"
                                />
                                <div
                                  *ngIf="
                                    (item.get('zipCode').valid &&
                                      (item.get('zipCode').dirty ||
                                        item.get('zipCode').touched)) ||
                                    (submitted && item.get('zipCode').valid)
                                  "
                                  class="valid-feedback"
                                >
                                  Looks good!
                                </div>
                                <div
                                  *ngIf="
                                    (item.get('zipCode').invalid &&
                                      (item.get('zipCode').dirty ||
                                        item.get('zipCode').touched)) ||
                                    (submitted && item.get('zipCode').invalid)
                                  "
                                  class="invalid-feeback"
                                >
                                  <app-field-errors
                                    [control]="item.get('zipCode')"
                                    [errorMessages]="formErrorMessages.zipCode"
                                    [showErrors]="submitted"
                                  ></app-field-errors>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
