<div class="wrapper">
  <div class="picture-container">
    <a
      href="https://app.oneparkfinancial.com/article/american-small-business-gets-funded-2020"
    >
      <img
        src="../../../../assets/logo.png"
        alt="Applications | One Park Financial"
        width="200"
      />
    </a>
  </div>

  <div *ngIf="isTokenValid$ | async; else tokenIsInvalid">
    <div *ngIf="!isPasswordReset">
      <div class="header">
        <h1>
          {{ 'reset_password_page.please_set_your_new_password' | translate }}
        </h1>
      </div>

      <div class="input-area">
        <div class="input__label">
          <label for="newPassword"
            >{{ 'password_page.password' | translate }}Password</label
          >
          <input
            class="email__input form-container__necessary"
            id="newPassword"
            type="password"
            [formControl]="newPassword"
          />
          <app-field-errors
            [control]="newPassword"
            [errorMessages]="formErrorMessages.newPassword"
          ></app-field-errors>
        </div>
        <div class="input__label">
          <label for="confirmPassword">{{
            'reset_password_page.confirm_password' | translate
          }}</label>
          <input
            class="email__input form-container__necessary"
            id="confirmPassword"
            type="password"
            [formControl]="confirmPassword"
          />
        </div>
        <app-field-errors
          errorMessage="Passwords do not match."
          [showErrors]="showConfirmPasswordError"
        >
        </app-field-errors>

        <button
          (click)="onResetPassword()"
          [disabled]="newPassword.invalid"
          class="submit-button"
        >
          {{ 'reset_password_page.reset_password' | translate }}
        </button>
      </div>
    </div>

    <div *ngIf="isPasswordReset">
      <div class="header">
        <h1>
          {{ 'reset_password_page.your_password_was_changed' | translate }}
        </h1>
      </div>

      <button routerLink="/lp/nt">
        {{ 'reset_password_page.go_to_main_page' | translate }}
      </button>
    </div>
  </div>

  <ng-template #tokenIsInvalid>
    <div class="header" *ngIf="(isLoading$ | async) !== true">
      <h1>{{ 'reset_password_page.link_is_invalid' | translate }}</h1>
    </div>
  </ng-template>

  <div class="footer">
    <p class="footer-text">
      {{ 'application_flow_component.info_safe_1' | translate }} <br />
      {{ 'application_flow_component.info_safe_2' | translate }}
    </p>
    <div class="links">
      <a href="https://www.oneparkfinancial.com/terms-of-use">{{
        'registration_form.terms_of_use' | translate
      }}</a>
      <a href="https://www.oneparkfinancial.com/privacy-policy">{{
        'registration_form.privacy_policy' | translate
      }}</a>
    </div>
  </div>
</div>
