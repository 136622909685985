<!-- <div *ngIf="!smallDollar" class="wrapper">
  <h1>{{ 'complete_page.main_header' | translate }}</h1>
  <h2>
    {{ 'complete_page.main_instruction_1' | translate }}
    <br />
    {{ 'complete_page.main_instruction_2' | translate }}
  </h2>
  <div class="bottom-text">{{ 'complete_page.main_closing' | translate }}</div>
  <div button class="btn btn-lg btn-primary" routerLink="/application/overview">
    {{ 'complete_page.main_button' | translate }}
  </div>
</div> -->

<div class="wrapper">
  <h1>{{ 'complete_page.main_header' | translate }}</h1>
  <h3>
    {{ 'complete_page.sd_heading' | translate }}
  </h3>
  <div class="row justify-content-center">
    <div class="col-12 col-lg-10 col-xl-8">
      <div class="row row-cols-1 row-cols-md-3 g-3 mt-3">
        <div class="col">
          <div class="card h-100 shadow py-3">
            <div class="card-body">
              <i class="fa-duotone fa-circle-1 fs-1 text-secondary"></i>
              <hr />
              <h5>{{ 'complete_page.sd_instruction_1' | translate }}</h5>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100 shadow py-3">
            <div class="card-body">
              <i class="fa-duotone fa-circle-2 fs-1 text-secondary"></i>
              <hr />
              <h5>{{ 'complete_page.sd_instruction_3' | translate }}</h5>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100 shadow py-3">
            <div class="card-body">
              <i class="fa-duotone fa-circle-3 fs-1 text-secondary"></i>
              <hr />
              <h5 *ngIf="flowId === '11'; else elseBlock">
                {{ 'complete_page.sd_instruction_4' | translate }}
              </h5>
              <ng-template #elseBlock>
                <h5>
                  {{ 'complete_page.sd_instruction_2' | translate }}
                </h5>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <h5 class="mt-5">{{ 'complete_page.sd_closing' | translate }}</h5>
    </div>
  </div>
</div>
