import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { TokenService } from '../../services/token-service';
import { LeadService } from '../../services/lead.service';
import { ErrorModel } from 'src/app/models/errors/error.model';
import { generalInfoUrl } from '../../constants';
import { loginFormErrorMessages } from '../../constants/form-error-messages-dictionaries';
import {
  WelcomeBackPopupComponent,
  WelcomeBackPopupResult,
} from '../shared/welcome-back-popup/welcome-back-popup.component';
import { NotificationService } from 'src/app/services/notifications-service';
import { MatDialog } from '@angular/material/dialog';
import { OnDestroy } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  errorMessage: string = '';
  formErrorMessages = loginFormErrorMessages;
  form: FormGroup = this.fb.group({
    email: [
      '',
      { updateOn: 'blur', validators: [Validators.required, Validators.email] },
    ],
    password: ['', { updateOn: 'blur', validators: [Validators.required] }],
  });

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private tokenService: TokenService,
    private leadService: LeadService,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    document.querySelector('body').classList.add('light');

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.email) {
        this.form.controls.email.setValue(params.email);
        this.openWelcomeBackPopup(params.email);
      }
    });
  }

  openWelcomeBackPopup(email: string): void {
    const dialogRef = this.dialog.open(WelcomeBackPopupComponent);

    dialogRef
      .afterClosed()
      .subscribe((dialogResult: WelcomeBackPopupResult) => {
        switch (dialogResult) {
          case WelcomeBackPopupResult.LoginWithPassword:
            close();
            break;
          case WelcomeBackPopupResult.SendGeneratePasswordEmail:
            this.leadService.sendGeneratePasswordEmail(email).subscribe(
              () => {
                this.notificationService.showSuccess(
                  'A Secure Password has been sent to the email we have on file. Please check your inbox.'
                );
              },
              () => {
                this.notificationService.showError(
                  null,
                  'Error sending generated password email.'
                );
              }
            );
            break;
        }
      });
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.leadService.login(this.email.value, this.password.value).subscribe(
        (result) => {
          if (result && result.token != null) {
            this.tokenService.setToken(result.token);

            this.router.navigate([generalInfoUrl]);
          } else {
            this.errorMessage = 'Unexpected Error';
          }
        },
        (error: ErrorModel) => {
          this.errorMessage = error.message || 'Unexpected Error';
        }
      );
    }
  }

  get email(): AbstractControl {
    return this.form.get('email') as AbstractControl;
  }
  get password(): AbstractControl {
    return this.form.get('password') as AbstractControl;
  }

  ngOnDestroy(): void {
    document.querySelector('body').classList.remove('light');
  }
}
