import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { activatedStepsByFlowId } from '../components/application-flow/constants/flow-id.const';
import { ApplicationFlowStepsEnum } from '../components/application-flow/steps-config/application-flow-steps.enum';
import { activeStepsContent } from '../components/application-flow/application-flow-steps/steps-info.const';
import { StepsInfo } from '../components/application-flow/application-flow-steps/application-flow-interface';
import { LeadService } from './lead.service';

@Injectable({
  providedIn: 'root',
})
export class FlowIdService {
  queryParams = new URLSearchParams(window.location.search);
  flowIdParam = this.queryParams.get('flowId');
  activatedStepsByFlowId;
  ApplicationFlowStepsEnum;
  activeStepsContent;
  StepsInfo;

  constructor(private leadService: LeadService) {}

  setFlowId(flowId?: string): void {
    if (flowId && flowId.length > 0) {
      localStorage.setItem('flowId', flowId);
    }
  }

  getFlowId(): string | null {
    return localStorage.getItem('flowId') || '0';
  }

  getStepsByFlowId(flowId: string): Observable<StepsInfo[]> {
    return this.leadService.requestState.pipe(
      map((state) => {
        const stepCompletionState = {
          [ApplicationFlowStepsEnum.BusinessInfo]:
            state.isBusinessInfoCompleted,
          [ApplicationFlowStepsEnum.Reports]: state.isReportsCompleted,
          [ApplicationFlowStepsEnum.Docusign]: state.isDocuSignCompleted,
          [ApplicationFlowStepsEnum.GeneralInfo]: state.isGeneralInfoCompleted,
          [ApplicationFlowStepsEnum.OwnersInfo]: state.isOwnersInfoCompleted,
        };
        return activatedStepsByFlowId[flowId ? flowId : 0].map((x: string) => ({
          ...activeStepsContent[x],
          isCompleted: stepCompletionState[activeStepsContent[x].url],
        }));
      })
    );
  }
}
