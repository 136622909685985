import { Injectable } from '@angular/core';

declare let gtag: (...args: unknown[]) => unknown;

@Injectable({
  providedIn: 'root'
})

export class AnalyticsService {

  constructor() {}

  public eventEmitter(
    waEventName: string,
    waEventCategory: string,
    waEventAction: string,
    waEventLabel: string = null,
    waEventValue: number = null
  ): void
  {
    gtag('event', waEventName, {
      waEventCategory,
      waEventLabel,
      waEventAction,
      waEventValue
    });
  }
}
