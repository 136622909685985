import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LeadService } from 'src/app/services/lead.service';
import { TokenService } from 'src/app/services/token-service';
import { questioneerFlowUrl } from '../../../constants';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
})
export class AppHeaderComponent implements OnInit {
  isAuthenticated$: Observable<boolean> = this.tokenService.isAuthenticated();
  isProfileMenuOpen: boolean = false;
  isSpanish: boolean = false;

  constructor(
    private tokenService: TokenService,
    private router: Router,
    private translate: TranslateService,
    public leadService: LeadService
  ) {}

  ngOnInit(): void {}

  login(): void {
    alert('not implemented yet');
  }

  logout(): void {
    this.tokenService.logout();
    this.router.navigate([questioneerFlowUrl]);
  }

  isCurrentLang(lang: string): boolean {
    const currentLang = this.translate.currentLang;

    // page load - default lang en - currentLang == undefined
    if (!currentLang && lang === 'en') {
      return true;
    } else {
      return lang === currentLang;
    }
  }

  switchLanguage(language: string): void {
    this.translate.use(language);
  }

  toggleProfileMenu(): void {
    this.isProfileMenuOpen = !this.isProfileMenuOpen;
  }
}
