<div class="wrapper">
  <app-loader *ngIf="isPageAvailable" class="loader"></app-loader>
  <div *ngIf="isPageAvailable">
    <div *ngIf="url !== null && !fileSigned" class="frame-wrapper">
      <iframe [src]="url | safe" title="DocUsign" class="frame" data-hj-allow-iframe="true"></iframe>
    </div>
    <div *ngIf="fileSigned">
      <app-files-list [files]="files"></app-files-list>
    </div>
  </div>

  <div class="not-available" *ngIf="!isPageAvailable">
    {{ 'docusign_page.signing_document_isnt_available' | translate }}
  </div>
</div>

