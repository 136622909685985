import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { Injectable, Inject, Optional } from '@angular/core';
import { API_BASE_URL } from './base-api-service';
import { MonitoringService, TransactionType } from 'src/app/services/monitoring.service';

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  private hubConnection: HubConnection = {} as HubConnection;
  private baseUrl: string;

  constructor(
    private monitoringService: MonitoringService,
    @Optional() @Inject(API_BASE_URL) baseUrl?: string
  ) {
    this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : '';
  }

  public startConnection(): Promise<void> {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(`${this.baseUrl}/notifications`, {
        skipNegotiation: false,
      })
      .build();

    const span = this.monitoringService.startSpan(TransactionType.ApiCall, 'POST /notifications/negotiate');
    return this.hubConnection
      .start()
      .then(() => {
        this.monitoringService.endSpan(span);
        console.log('Connection started');
      })
      .catch((err) =>
      {
        console.log('Error while starting connection: ' + err);
        throw err;
      });
  }

  public subscribe(channel: string, handler: any): void {
    this.hubConnection.on(channel, handler);
  }

  public unsubscribe(channel: string, handler: any): void {
    this.hubConnection.off(channel, handler);
  }
}
