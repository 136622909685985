import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RequiredQueryParamsGuard implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot): boolean {
    const requiredQueryParams: string[] = route.data.requiredQueryParams;

    return !requiredQueryParams.some(
      (rp) => !route.queryParamMap.keys.includes(rp)
    );
  }
}
