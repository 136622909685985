import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

export enum FinicityErrorPopupPopupResult {
  RedirectToManualUploadPage,
  RedirectToPreviousPage,
}

@Component({
  selector: 'app-finicity-error-popup',
  templateUrl: './finicity-error-popup.component.html',
  styleUrls: ['./finicity-error-popup.component.scss'],
})
export class FinicityErrorPopupComponent {
  popupResults = {
    redirectToManualUploadPage:
      FinicityErrorPopupPopupResult.RedirectToManualUploadPage,
    redirectToPreviousPage:
      FinicityErrorPopupPopupResult.RedirectToPreviousPage,
  };

  constructor(private dialogRef: MatDialogRef<FinicityErrorPopupComponent>) {}

  onSubmit(popupResult: FinicityErrorPopupPopupResult): void {
    this.dialogRef.close(popupResult);
  }
}
