import { Injectable } from '@angular/core';
import { LeadService } from './lead.service';
import { ApplicationFlowStepsEnum } from '../components/application-flow/steps-config/application-flow-steps.enum';

@Injectable({
  providedIn: 'root',
})
export class CompletedStepsService {
  isReportsCompleted: boolean = false;

  constructor(private leadService: LeadService) {}

  getFirstNotCompletedStep(): ApplicationFlowStepsEnum {
    const statesMapping = [
      {
        isCompleted: this.leadService.isGeneralInfoCompleted,
        url: ApplicationFlowStepsEnum.GeneralInfo,
      },
      {
        isCompleted: this.leadService.isBusinessInfoCompleted,
        url: ApplicationFlowStepsEnum.BusinessInfo,
      },
      {
        isCompleted: this.leadService.isOwnersInfoCompleted,
        url: ApplicationFlowStepsEnum.OwnersInfo,
      },
      {
        isCompleted: this.leadService.isDocuSignCompleted,
        url: ApplicationFlowStepsEnum.Docusign,
      },
      {
        isCompleted: this.leadService.isReportsCompleted,
        url: ApplicationFlowStepsEnum.Reports,
      },
    ];

    let firstNotCompletedStep = statesMapping.find((el) => !el.isCompleted);

    if (firstNotCompletedStep == null) {
      firstNotCompletedStep = statesMapping[statesMapping.length - 1];
    }

    this.leadService.isLoggedIn = false;

    return firstNotCompletedStep['url'];
  }
}
