import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UtmService } from '../../services/utm-service';
import { Utm } from '../../models/utm.model';

@Component({
  selector: 'app-decline',
  templateUrl: './decline.component.html',
  styleUrls: ['./decline.component.scss'],
})
export class DeclineComponent implements OnInit, AfterViewInit {
  constructor(private readonly utmService: UtmService) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const scriptContainer: any = document.getElementById(
      'partners-script-container'
    );

    const script = document.createElement('script');
    script.src = 'https://api.lincx.com/load';
    script.type = 'text/javascript';
    script.async = true;

    const utm: Utm = this.utmService.getUtm();

    script.setAttribute('data-zone-id', 'ow7pir');
    script.setAttribute('data-affsub', utm.utmMedium ?? '');
    script.setAttribute('data-category', 'personal');
    script.setAttribute('data-origin-id', '6703tuk');
    script.setAttribute('data-s3', utm.gclId);

    scriptContainer.appendChild(script);
  }
}
