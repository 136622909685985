import { AfterViewInit, Component, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LeadService } from '../../services/lead.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password-mail.component.html',
  styleUrls: ['./reset-password-mail.component.scss'],
})
export class ResetPasswordMailComponent implements AfterViewInit {
  email: FormControl = new FormControl();

  mailSent: boolean = false;

  constructor(
    private leadService: LeadService,
    private elementRef: ElementRef
  ) {}

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor =
      'white';
  }

  onSendPasswordLink(): void {
    this.leadService
      .sendResetPasswordMail(this.email.value)
      .subscribe(() => (this.mailSent = true));
  }
}
