import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-overview-app-flow',
  templateUrl: './overview-app-flow.component.html',
  styleUrls: ['./overview-app-flow.component.scss'],
})
export class OverviewAppFlowComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
