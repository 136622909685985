<div class="reports-container">
  <div class="wrapper">
    <form action="">
      <table class="table table-hover table-striped">
        <thead class="">
          <tr>
            <th scope="col">
              <span class="visually-hidden">Document Number</span>
            </th>
            <th scope="col">
              <span class="fw-light fs-6">{{
                'reports_page.document' | translate
              }}</span>
            </th>
            <th class=".d-xs-none .d-sm-none" scope="col">
              <span class="fw-light fs-6">{{
                'reports_page.description' | translate
              }}</span>
            </th>
            <th class="text-center" scope="col">
              <span class="fw-light fs-6">{{
                'reports_page.action' | translate
              }}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th class="text-center align-middle" scope="row">1</th>
            <td class="align-middle">
              <span class="fw-bold">
                {{ 'reports_page.bank_statement_for' | translate }}
                {{
                  'months.' +
                    getDocumentMonth(requestDocumentResponseType.BankStatement1)
                    | translate
                }}
                {{
                  getDocumentYear(requestDocumentResponseType.BankStatement1)
                }}
              </span>
            </td>
            <td class="align-middle">
              <p
                class="fs-6 text-bold"
                *ngIf="statementIsFinicity[0]; else elseBlock1"
              >
                {{ 'reports_page.bank_statement' | translate }}
              </p>
              <p #elseBlock1 class="fs-6 text-bold">
                {{ 'reports_page.bank_statement_manual' | translate }}
              </p>

              <p class="text-muted">
                {{ 'reports_page.file_restrictions' | translate }}
              </p>
            </td>
            <td class="text-center align-middle">
              <app-upload-button
                [documentType]="requestDocumentResponseType.BankStatement1"
              ></app-upload-button>
            </td>
          </tr>
          <tr>
            <th class="text-center align-middle" scope="row">2</th>
            <td class="align-middle">
              <span class="fw-bold">
                {{ 'reports_page.bank_statement_for' | translate }}
                {{
                  'months.' +
                    getDocumentMonth(requestDocumentResponseType.BankStatement2)
                    | translate
                }}
                {{
                  getDocumentYear(requestDocumentResponseType.BankStatement2)
                }}
              </span>
            </td>
            <td class="align-middle">
              <p
                class="fs-6 text-bold"
                *ngIf="statementIsFinicity[1]; else elseBlock2"
              >
                {{ 'reports_page.bank_statement' | translate }}
              </p>
              <p #elseBlock2 class="fs-6 text-bold">
                {{ 'reports_page.bank_statement_manual' | translate }}
              </p>
              <p class="text-muted">
                {{ 'reports_page.file_restrictions' | translate }}
              </p>
            </td>
            <td class="text-center align-middle">
              <app-upload-button
                [documentType]="requestDocumentResponseType.BankStatement2"
              ></app-upload-button>
            </td>
          </tr>
          <tr>
            <th class="text-center align-middle" scope="row">3</th>
            <td class="align-middle">
              <span class="fw-bold">
                {{ 'reports_page.bank_statement_for' | translate }}
                {{
                  'months.' +
                    getDocumentMonth(requestDocumentResponseType.BankStatement3)
                    | translate
                }}
                {{
                  getDocumentYear(requestDocumentResponseType.BankStatement3)
                }}
              </span>
            </td>
            <td class="align-middle">
              <p
                class="fs-6 text-bold"
                *ngIf="statementIsFinicity[2]; else elseBlock3"
              >
                {{ 'reports_page.bank_statement' | translate }}
              </p>
              <p class="fs-6 text-bold" #elseBlock3>
                {{ 'reports_page.bank_statement_manual' | translate }}
              </p>
              <p class="text-muted">
                {{ 'reports_page.file_restrictions' | translate }}
              </p>
            </td>
            <td class="text-center align-middle">
              <app-upload-button
                [documentType]="requestDocumentResponseType.BankStatement3"
              >
              </app-upload-button>
            </td>
          </tr>
          <tr>
            <th class="text-center align-middle" scope="row">4</th>
            <td class="align-middle">
              <span class="fw-bold">{{
                'reports_page.government_id' | translate
              }}</span>
            </td>
            <td class="align-middle">
              <p class="fs-6 text-bold">
                {{ 'reports_page.government_id_description' | translate }}
              </p>
              <p class="text-muted">
                {{ 'reports_page.file_restrictions' | translate }}
              </p>
            </td>
            <td class="text-center align-middle">
              <app-upload-button
                [documentType]="requestDocumentResponseType.GovernmentId"
              >
              </app-upload-button>
            </td>
          </tr>
        </tbody>
      </table>
      <div id="invalid-feedback" *ngIf="errorMessage; else elseBlock">
        {{ errorMessage }}
      </div>
      <ng-template #elseBlock>
        <div>
          {{ 'reports_page.disclaimer' | translate }}
        </div>
      </ng-template>
      <div class="not-allowed" *ngIf="!isFinicityAllowed">
        {{ 'reports_page.finicity_connect_not_allowed' | translate }}
      </div>
    </form>
  </div>
</div>

<div *ngIf="finicityFiles$ | async as finicityFiles">
  <div *ngIf="finicityFiles.length > 0">
    <div>{{ 'standard_or_finicity.finicity_files' | translate }}</div>
    <app-files-list [files]="finicityFiles"></app-files-list>
  </div>
</div>
