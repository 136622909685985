import { Injectable } from '@angular/core';
import { SignalRService } from './signal-r.service';
import { NotificationService } from './notifications-service';
import {
  FinicityNotificationModel,
  WebHookCustomerModel,
  WebHookStatementProcessingModel,
} from '../models/web-hook-notifications/finicity';
import { WebHookType } from '../models/web-hook-notifications/webhook-type.enum';
import { EventType } from '../models/web-hook-notifications/event-type.enum';
import { FilesService } from './files.service';
import { RequestFileResponse } from '../models/responses/request-file.model';
import { LeadService } from './lead.service';
import { BehaviorSubject } from 'rxjs';

const FinicityCustomerPrefix = 'Finicity connection: ';
const FinicityStatementsPrefix = 'Finicity Statements: ';

const finicityError =
  'something whent wrong. Try to connect Finicity again please';

@Injectable({
  providedIn: 'root',
})
export class FinicityIntegrationService {
  constructor(
    private signalRService: SignalRService,
    private notificationsService: NotificationService,
    private filesService: FilesService,
    private leadService: LeadService
  ) {}

  private customerId: string | null = null;
  error: BehaviorSubject<string> = new BehaviorSubject(null);

  public initSubscription(customerId: string): void {
    if (this.customerId === customerId) {
      return;
    }

    const channel = `finicity/processing-notification/${customerId}`;
    const action = this.notificationReceived.bind(this);

    if (this.customerId != null) {
      const previousChannel = `finicity/processing-notification/${this.customerId}`;
      this.signalRService.unsubscribe(previousChannel, action);
    }

    this.signalRService.subscribe(channel, action);

    this.customerId = customerId;
  }

  private notificationReceived(data: FinicityNotificationModel): void {
    const webHookType: WebHookType = WebHookType[
      data.webHookType
    ] as WebHookType;
    const eventType: EventType = EventType[data.eventType] as EventType;

    let prefix: string = '';
    let successMessage: string | null = null;

    switch (webHookType) {
      case WebHookType.FinicityCustomer: {
        prefix = FinicityCustomerPrefix;
        break;
      }
      case WebHookType.FinicityStatementProcessing: {
        prefix = FinicityStatementsPrefix;
        break;
      }
    }

    if (eventType === EventType.Error) {
      const message = prefix + finicityError;
      this.onFinicityError(message);
      return;
    }

    if (webHookType === WebHookType.FinicityCustomer) {
      const dataModel = data.dataModel as WebHookCustomerModel;
      if (dataModel.eventType !== 'done') {
        return;
      }

      successMessage = 'data received. Start to load bank statements';
    } else if (webHookType === WebHookType.FinicityStatementProcessing) {
      const dataModel = data.dataModel as WebHookStatementProcessingModel;

      switch (dataModel.eventName) {
        case 'inProgress': {
          successMessage = 'bank statement generating';
          break;
        }
        case 'done': {
          successMessage = 'bank statement downloaded successfully';
          this.filesService.addFile(
            JSON.parse(dataModel.payload) as RequestFileResponse
          );
          this.leadService.updateRequestState({
            ...this.leadService.requestState.value,
            isReportsCompleted: true,
          });
          break;
        }
        case 'failure': {
          const message =
            prefix +
            'error during generating bank statement at Finicity Side. Please, upload documents manually or contact support.';
          this.onFinicityError(message);
          return;
        }
      }
    }

    if (successMessage != null) {
      this.notificationsService.showSuccess(prefix + successMessage);
    }
  }

  private onFinicityError(message: string): void {
    this.notificationsService.showError(null, message);
    this.error.next(message);
  }
}
