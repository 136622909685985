import {
  AbstractControl,
  AsyncValidator,
  ValidationErrors,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LeadService } from './lead.service';

@Injectable({ providedIn: 'root' })
export class PhoneAsyncValidator implements AsyncValidator {
  private leadService: LeadService;

  constructor(leadService: LeadService) {
    this.leadService = leadService;
  }

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    const value = control.value;
    return this.leadService
      .isPhoneValid(value)
      .pipe(
        map((isPhoneValid) =>
          isPhoneValid ? null : { invalidPhone: isPhoneValid }
        )
      );
  }
}
