export enum QuestioneerFlowStepsEnum {
  BusinessOpen = '1-is-business-open',
  Revenue = '2-monthly-revenue',
  StartDate = '3-business-started',
  CheckingAccount = '4-has-checking-account',
  ActiveBankruptcy = '5-bankcruptcy',
  MoneyNeed = '6-requested-amount',
  CompanyName = '7-company-name',
  Email = '8-your-email-address',
  PhoneNumber = '9-your-phone',
  FullName = '10-your-fullname',
}
