<div>
  <div class="warning-message-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-exclamation-circle"
      viewBox="0 0 32 32" str>
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
      <path
        d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
    </svg>
    <div class="warning-message">
      {{ 'finicity_page.you_must_select' | translate }}
      <span class="bold-text">{{'finicity_page.business_checking_account' | translate}}</span>
      {{ 'finicity_page.in_order_to_process' | translate }}
      <span class="bold-text">{{'finicity_page.personal_checking_account' | translate}}</span>
      {{ 'finicity_page.or_any_other' | translate }}
      <span class="bold-text">{{'finicity_page.business_checking_account' | translate}}</span>
      {{ 'finicity_page.result_to_decline' | translate }}
    </div>
  </div>
  <div class="finicity-container"></div>
</div>