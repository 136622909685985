import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { environment } from '../../environments/environment';
import * as Sentry from '@sentry/angular';

@Injectable({
  providedIn: 'root',
})
export class SmartGoogleTagManagerService {

  constructor(private gtmService: GoogleTagManagerService) {
    // disabled: this.gtmService.pushTag() calls addGtmToDom()

    // if (environment.useGtm) {
    //   gtmService.addGtmToDom().catch((e) => {
    //     // Silently ignore GTM script errors. Usually it happens on too old browser versions.
    //     // But also it may be caused by an invalid configuration, so let's send it to Sentry as a custom error just in case.
    //     Sentry.captureException(e);
    //   });
    // }
  }

  public pushTag(tag: Record<string, unknown>): Promise<void> {
    if (environment.useGtm) {
      return this.gtmService.pushTag(tag).catch((e) => {
        // Silently ignore GTM script errors. Usually it happens on too old browser versions.
        // But also it may be caused by an invalid configuration, so let's send it to Sentry as a custom error just in case.
        Sentry.captureException(e);
      });;
    }
  }
}
