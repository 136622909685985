import { RequestDocumentResponseType } from '../models/responses/request-file.model';

export const getStatementMonthDiff = (
  documentType: RequestDocumentResponseType
): number => {
  let monthsDiff: number;
  switch (documentType) {
    case RequestDocumentResponseType.BankStatement1.toString():
      monthsDiff = 1;
      break;
    case RequestDocumentResponseType.BankStatement2.toString():
      monthsDiff = 2;
      break;
    case RequestDocumentResponseType.BankStatement3.toString():
      monthsDiff = 3;
      break;
  }

  return monthsDiff;
};
