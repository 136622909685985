import { Injectable } from '@angular/core';
import { FormErrorsEnum } from '../enum/FormErrors.enum';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  getErrorsMessages(
    fieldKey: string | null,
    errorKey: string,
    errorObject: any
  ): string[] {
    // TODO: add localization
    // TODO: fix format of messages

    switch (errorKey) {
      case FormErrorsEnum.IncorrectLength: {
        const { actualLength, requiredLength } = errorObject;
        if (fieldKey != null) {
          // TODO: Provide fieldKey and than use Localisation map key -> friendly field name to use errors like "First name is required"
          return [
            `${fieldKey} is incorrect. It's should contains ${requiredLength} symbols, actual entered: ${actualLength}`,
          ];
        }
        return [
          `Should contains ${requiredLength} symbols, actual entered: ${actualLength}`,
        ];
      }

      case FormErrorsEnum.Required: {
        if (fieldKey != null) {
          return [`${fieldKey} Is Required`];
        }
        return ['Required'];
      }
      case FormErrorsEnum.InvalidPhone: {
        return ['Phone number is not valid'];
      }
      case FormErrorsEnum.Email: {
        return ['Email is not valid'];
      }
      case FormErrorsEnum.Max: {
        const { max } = errorObject;
        return [`Value should be lower than or equal to ${max}`];
      }
      case FormErrorsEnum.Min: {
        const { min } = errorObject;
        return [`Value should be greater than or equal to  ${min}`];
      }
      case FormErrorsEnum.MaxLength: {
        const { actualLength, requiredLength } = errorObject;

        return [
          `Max length is ${requiredLength}, actual entered: ${actualLength}`,
        ];
      }

      case FormErrorsEnum.Pattern: {
        return ['Field format not supported'];
      }

      case FormErrorsEnum.MinAgeRestriction: {
        return [`Should be at least ${errorObject.expectedAge} years old.`];
      }

      case FormErrorsEnum.MaxAgeRestriction: {
        return ['Invalid date of birth, please correct birth year.'];
      }
    }

    return [];
  }
}
