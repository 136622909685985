import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// this is needed for Sentry/rrweb integration
import type { } from 'css-font-loading-module';

import * as Sentry from '@sentry/angular';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import SentryRRWeb from '@sentry/rrweb';

if (environment.production) {
  enableProdMode();
}

if (environment.monitoring.sentry.enabled) {
  Sentry.init({
    ...environment.monitoring.sentry,
    integrations: [
      new SentryRRWeb({
        checkoutEveryNth: 100,
        maskAllInputs: false,
      }),
      new TracingIntegrations.BrowserTracing({
        tracingOrigins: [environment.apiBaseUrl],
        routingInstrumentation: Sentry.instrumentAngularRouting,
      }),
      new Sentry.Integrations.Breadcrumbs({
        console: false
      })
    ],
    autoSessionTracking: true,
    attachStacktrace: true,
    tracesSampleRate: 1.0,
    ignoreErrors: [
      // Seems safe to be ignored: https://github.com/getsentry/sentry-javascript/issues/2292
      'Non-Error exception captured',
      // Network errors such as going offline or being blocked by a proxy
      'Failed to fetch'
    ]
  });

  Sentry.setTag('rrweb.active', 'yes');
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
