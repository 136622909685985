import { Component, Input, OnInit } from '@angular/core';
import { FilesService } from '../../../../../services/files.service';
import { RequestFileResponse } from '../../../../../models/responses/request-file.model';

@Component({
  selector: 'app-statement-info',
  templateUrl: './statement-info.component.html',
  styleUrls: ['./statement-info.component.scss'],
})
export class StatementInfoComponent implements OnInit {
  @Input() documentType: string;

  files: RequestFileResponse[];

  get isUploaded(): boolean {
    return this.files.some((x) => x.fileName.includes(this.documentType));
  }

  constructor(private fileService: FilesService) {}

  ngOnInit(): void {
    this.fileService.files.subscribe((data) => (this.files = data));
  }
}
