import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { LeadService } from '../../services/lead.service';
import { ActivatedRoute } from '@angular/router';
import { map, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LoadingService } from '../../services/loading.service';
import { resetPasswordFormErrorMessages } from './reset-password.formErrorMessages';
import {
  lowercaseAlphabetsRegex,
  minPasswordLength,
  numericsCharactersRegex,
  uppercaseAlphabetsRegex,
} from '../../constants';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  formErrorMessages = resetPasswordFormErrorMessages;

  newPassword: FormControl = new FormControl(null, {
    updateOn: 'blur',
    validators: [
      Validators.required,
      Validators.pattern(numericsCharactersRegex),
      Validators.pattern(uppercaseAlphabetsRegex),
      Validators.pattern(lowercaseAlphabetsRegex),
      Validators.minLength(minPasswordLength),
    ],
  });
  confirmPassword: FormControl = new FormControl(null);

  showConfirmPasswordError: boolean = false;
  isPasswordReset: boolean;

  isTokenValid$: Observable<boolean>;
  isLoading$: Observable<boolean>;

  constructor(
    private leadService: LeadService,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.isLoading$ = this.loadingService.isLoading();
    this.isTokenValid$ = this.activatedRoute.queryParams.pipe(
      mergeMap((params) =>
        this.leadService.validateResetPasswordToken(params['token'])
      ),
      map((response) => response.isTokenValid)
    );
  }

  onResetPassword(): void {
    if (this.newPassword.value !== this.confirmPassword.value) {
      this.showConfirmPasswordError = true;
      return;
    }
    this.showConfirmPasswordError = false;

    const token: string = this.activatedRoute.snapshot.queryParams.token;

    this.leadService
      .resetPassword(token, this.newPassword.value)
      .subscribe(() => (this.isPasswordReset = true));
  }
}
