<div class="flow-steps-list">
  <ul>
    <li>
      <a href="">
        <div class="vertical-line"></div>
        <div>
          <div class="green-circle-picture">
            <span>
              <svg
                class="green-elements"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </span>
          </div>
        </div>
        <div class="flow-step-description">
          <h3>{{ 'flow_steps_names.general' | translate }}</h3>
          <p>
            Congrats! You are one step closer to getting your business funded
          </p>
        </div>
      </a>
    </li>
    <li>
      <a href="">
        <div class="vertical-line"></div>
        <div>
          <div class="green-circle-picture">
            <span>
              <svg
                class="green-elements"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </span>
          </div>
        </div>
        <div class="flow-step-description">
          <h3>{{ 'flow_steps_names.business' | translate }}</h3>
          <p>Basic information about your company</p>
        </div>
      </a>
    </li>
    <li>
      <a href="">
        <div class="vertical-line"></div>
        <div>
          <div class="green-circle-picture">
            <span>
              <svg
                class="green-elements"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </span>
          </div>
        </div>
        <div class="flow-step-description">
          <h3>{{ 'flow_steps_names.owners' | translate }}</h3>
          <p>Tell us more about you / your partner(s)</p>
        </div>
      </a>
    </li>
    <li>
      <a href="">
        <div class="vertical-line"></div>
        <div>
          <div class="green-circle-picture">
            <span>
              <svg
                class="green-elements"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </span>
          </div>
        </div>
        <div class="flow-step-description">
          <h3>(Placeholder) DocuSign</h3>
          <p>Funding Application Signature</p>
        </div>
      </a>
    </li>
    <li>
      <a href="">
        <div>
          <div class="green-circle-picture">
            <span>
              <svg
                class="green-elements"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </span>
          </div>
        </div>
        <div class="flow-step-description">
          <h3>(Placeholder) Documents</h3>
          <p>Please, continue with your application to get funded.</p>
        </div>
      </a>
    </li>
  </ul>
</div>
