<div class="d-flex bd-highlight">
  <div class="bd-highlight p-2"><h5>Oops, there was an error.</h5></div>
  <div class="ms-auto bd-highlight p-2">
    <button
      type="button"
      class="btn-close"
      (click)="onSubmit(popupResults.redirectToPreviousPage)"
    ></button>
  </div>
</div>
<hr class="m-0" />
<div class="p-2">
  <div class="d-flex justify-content-center">
    <p>Please upload business bank statements</p>
  </div>
  <div class="d-flex justify-content-center">
    <button
      type="button"
      class="btn btn-primary rounded-pill"
      (click)="onSubmit(popupResults.redirectToManualUploadPage)"
    >
      Manual Upload
    </button>
  </div>
</div>
