import { Component, Input, OnInit } from '@angular/core';
import { NotificationService } from '../../../services/notifications-service';
import { FilesService } from '../../../services/files.service';
import { RequestDocumentResponseType } from '../../../models/responses/request-file.model';
import * as moment from 'moment';
import { getStatementMonthDiff } from '../../../utils/document-type.util';
import { appendStringBeforeFileExtension } from '../../../utils/string.util';
import { BehaviorSubject } from 'rxjs';
import { LeadService } from '../../../services/lead.service';

@Component({
  selector: 'app-upload-button',
  templateUrl: './upload-button.component.html',
  styleUrls: ['./upload-button.component.scss'],
})
export class UploadButtonComponent implements OnInit {
  @Input() documentType: string;

  reportIds: string[] = [];
  uploaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  get inputId(): string {
    return `GovermentId_${this.documentType}`;
  }

  constructor(
    private notificationService: NotificationService,
    private fileService: FilesService,
    private leadService: LeadService
  ) {}

  ngOnInit(): void {
    this.fileService.files.subscribe((data) => {
      this.uploaded$.next(
        data.some((x) => x.fileName.includes(this.documentType))
      );
    });
  }

  fileSelected(event: any, filePrefix: string = null): void {
    const files: FileList = event.target.files;

    if (!files || !files.length) {
      return;
    }

    const file: File = files[0];

    if (file.size === 0) {
      this.notificationService.showWarning(
        null,
        'File was not uploaded. Empty files are not accepted.'
      ); // TODO: add localization
      return;
    }

    const monthsDiff = getStatementMonthDiff(
      this.documentType as RequestDocumentResponseType
    );
    const date = moment()
      .subtract(monthsDiff, 'months')
      .format('M-YYYY')
      .toString();

    const namePrefix = filePrefix ? `${filePrefix}_` : '';

    const fileNameWithDate = appendStringBeforeFileExtension(
      file.name,
      `:${date}`
    );

    const fileName = `${namePrefix}${this.documentType}_${fileNameWithDate}`;

    this.fileService.uploadDocument(file, fileName).subscribe(() => {
      this.uploadDocumentSuccess();
      this.leadService.updateRequestState({
        ...this.leadService.requestState.value,
        isReportsCompleted: true,
      });
    });
  }

  uploadDocumentSuccess(): void {
    this.notificationService.showSuccess('File uploaded correctly!'); // TODO: add localisation
    this.uploaded$.next(true);
  }
}
