import { Component, OnDestroy, OnInit } from '@angular/core';
import { RequestDocumentResponseType } from '../../../models/responses/request-file.model';
import { LeadService } from '../../../services/lead.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { PhoneAsyncValidator } from '../../../services/phone-async-validator';
import { CompletedStepsService } from '../../../services/completed-steps.service';
import { ApplicationFlowStepsEnum } from '../steps-config/application-flow-steps.enum';
import {
  maxPhoneLength,
  minPhoneLength,
  onlyNumbersRegex,
} from '../../../constants/validations.const';
import { NotificationService } from '../../../services/notifications-service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-overview-page',
  templateUrl: './overview-page.component.html',
  styleUrls: ['./overview-page.component.scss'],
})
export class OverviewPageComponent implements OnInit, OnDestroy {
  documentTypes = RequestDocumentResponseType;

  public isShow: boolean = false;
  public buttonName: any = 'Reset Password';
  icon: boolean = true;

  aboutForm: FormGroup = this.fb.group({
    firstName: [
      '',
      {
        updateOn: 'blur',
        validators: [Validators.required],
      },
    ],
    lastName: [
      '',
      {
        updateOn: 'blur',
        validators: [Validators.required],
      },
    ],
    phone: [
      '',
      {
        updateOn: 'blur',
        validators: [
          Validators.required,
          Validators.pattern(onlyNumbersRegex),
          Validators.maxLength(maxPhoneLength),
          Validators.minLength(minPhoneLength),
        ],
        asyncValidators: [new PhoneAsyncValidator(this.leadService)],
      },
    ],
    email: [
      '',
      {
        updateOn: 'blur',
        validators: [Validators.required, Validators.email],
      },
    ],
  });

  newPassword: FormControl = new FormControl();
  confirmPassword: FormControl = new FormControl();
  showConfirmPasswordError: boolean = false;
  changePasswordSub: Subscription;

  currentStep: ApplicationFlowStepsEnum;
  ApplicationFlowStepsEnum = ApplicationFlowStepsEnum;

  constructor(
    private fb: FormBuilder,
    public leadService: LeadService,
    private completedStepsService: CompletedStepsService,
    private notificationService: NotificationService
  ) {}

  ngOnDestroy(): void {
    if (this.changePasswordSub) {
      this.changePasswordSub.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.leadService
      .getApplicantContact()
      .subscribe((contact) => this.aboutForm.patchValue(contact));

    this.leadService.requestState.subscribe(() => {
      this.currentStep = this.completedStepsService.getFirstNotCompletedStep();
    });
  }

  toggle(): void {
    this.isShow = !this.isShow;
    this.icon = !this.icon;
    this.buttonName = this.isShow ? 'Hide' : 'Reset Password';
  }

  onResetPassword(): void {
    if (this.newPassword.value !== this.confirmPassword.value) {
      this.showConfirmPasswordError = true;
      return;
    }

    this.showConfirmPasswordError = false;

    this.changePasswordSub = this.leadService
      .changePassword(this.newPassword.value)
      .subscribe(() => {
        this.notificationService.showSuccess(
          'Your password was successfully changed'
        );
        this.confirmPassword.setValue('');
        this.newPassword.setValue('');
      });
  }

  get phone(): FormControl {
    return this.aboutForm.get('phone') as FormControl;
  }
}
