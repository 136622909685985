<div class="status-circle-container">
  <!-- <div
    *ngIf="renderVerticalLine"
    class="vertical-line d-sm-none d-md-block"
    [class.completed-vertical-line]="isCompleted || isCurrentStep"
  ></div> -->

  <span *ngIf="isCompleted" class="completed-circle">
    <svg
      class="completed-circle-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fill-rule="evenodd"
        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
        clip-rule="evenodd"
      ></path>
    </svg>
  </span>

  <span
    *ngIf="!isCompleted"
    class="not-completed-circle"
    [class.current-step]="isCurrentStep"
  >
    <span class="not-completed-circle-icon"></span>
  </span>
</div>
