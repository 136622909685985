import { Component, Input } from '@angular/core';
import { RequestFileResponse } from 'src/app/models/responses/request-file.model';
import { FilesService } from 'src/app/services/files.service';

@Component({
  selector: 'app-files-list',
  templateUrl: './files-list.component.html',
  styleUrls: ['./files-list.component.scss'],
})
export class FilesListComponent {
  @Input() files: RequestFileResponse[] = [];

  constructor(private filesService: FilesService) {}

  downloadFile(id: number, name: string): void {
    this.filesService.downloadFile(id, name);
  }
}
