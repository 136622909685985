import { AbstractControl, ValidatorFn } from '@angular/forms';

// TODO: check posibility of use compose of maxLength and minLength
function lenFunction(length: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value ? control.value.toString() : '';

    if (value.length !== length) {
      return {
        incorrectLength: { requiredLength: length, actualLength: value.length },
      };
    }

    return null;
  };
}

export class Validators {
  static len(length: number): ValidatorFn {
    return lenFunction(length);
  }

  static minAgeRestriction(minAge: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const enteredDate = new Date(
        control.value ? control.value.toString() : ''
      );

      const minDate = new Date();

      minDate.setFullYear(minDate.getFullYear() - minAge);

      return minDate >= enteredDate && (control.value).length === 10
        ? null
        : { minAgeRestriction: { value: control.value, expectedAge: minAge } };
    };
  }

  static maxAgeRestriction(maxAge: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const enteredDate = new Date(
        control.value ? control.value.toString() : ''
      );

      const maxDate = new Date();

      maxDate.setFullYear(maxDate.getFullYear() - maxAge);

      return maxDate <= enteredDate && (control.value).length === 10
        ? null
        : { maxAgeRestriction: { value: control.value } };
    };
  }
}
