<!-- <div class="flow-container"> -->
<app-header></app-header>

<!-- <div class="container-fluid container-xl"> -->
<div class="application-flow-container">
  <div class="container-fluid container-xl">
    <app-application-flow-steps></app-application-flow-steps>
  </div>

  <div class="container-fluid container-xl">
    <div class="main-container">
      <router-outlet (activate)="childActivated($event)"></router-outlet>
      <div class="col-12 text-center mt-5">
        <button
          class="btn btn-lg btn-primary"
          type="submit"
          (click)="next()"
          [ngClass]="isContinueButtonVisible ? 'invisible' : 'visible'"
        >
          Continue
        </button>
      </div>
    </div>
  </div>

  <footer class="text-center mt-5 bg-gray-light bg-opacity-10">
    <div class="leaf-divider top">
      <svg
        *ngIf="flowId === '11'"
        data-name="leaf-divider"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 136.1"
        preserveAspectRatio="none"
      >
        <path
          d="M0,0c189.8,283.5,691.8,36.7,1200,0C589.5,0,463.8,0,0,0"
          class="fill-white"
        ></path>
      </svg>
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-8">
          <div class="py-5">
            <p class="disclaimer">
              Funding offer turn around time is contingent the business owner's
              availability to work with the One Park Financial team. It's
              important that the business owner responds to our Funding
              Specialist and Manager to make the process the faster possible.
              Choosing the "FAST verification" does not guarantee a funding
              offer.
            </p>

            <p class="disclaimer">
              We use industry best practice security protocols to keep your
              information safe.
            </p>
            <p>
              <img
                src="https://secure.oneparkfinancial.com/assets/comodo.png"
                alt="Secure"
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</div>
<!-- </div> -->
<!-- </div> -->
