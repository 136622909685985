import { FormErrorsEnum } from '../enum/FormErrors.enum';
import { LocalizationKeysEnum } from '../enum/LocalizationKeys.enum';
import { Dictionary } from '../interfaces/common';

export const phoneErrorMessagesDictionary: Dictionary = {
  [FormErrorsEnum.Required]: LocalizationKeysEnum.PhoneRequired,
  [FormErrorsEnum.Pattern]: LocalizationKeysEnum.OnlyNumbersAllowed,
  [FormErrorsEnum.InvalidPhone]: LocalizationKeysEnum.PhoneIncorrect,
  [FormErrorsEnum.MaxLength]: LocalizationKeysEnum.PhoneMaxLength,
  [FormErrorsEnum.MinLength]: LocalizationKeysEnum.Min8,
};
