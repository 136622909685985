<div class="wrapper">
  <div class="footer-container">
    <div class="columns-container">
      <div class="footer-column menu-container">
        <h3>Menu</h3>
        <ul>
          <li>
            <a href="https://www.oneparkfinancial.com/">Home</a>
          </li>
          <li>
            <a href="https://www.oneparkfinancial.com/how-it-works"
              >How it Works</a
            >
          </li>
          <li>
            <a href="https://www.oneparkfinancial.com/about-us">About us</a>
          </li>
          <li>
            <a href="https://www.oneparkfinancial.com/press">Press Center</a>
          </li>
          <li>
            <a href="https://www.oneparkfinancial.com/blog">Blog</a>
          </li>
        </ul>
      </div>
      <div class="footer-column legal-container">
        <h3>Legal</h3>
        <ul>
          <li>
            <a href="https://www.oneparkfinancial.com/contact">Contact Us</a>
          </li>
          <li>
            <a href="https://www.oneparkfinancial.com/privacy-policy"
              >Privacy Policy</a
            >
          </li>
          <li>
            <a href="https://www.oneparkfinancial.com/terms-of-use"
              >Terms Of Use</a
            >
          </li>
          <li>
            <a href="https://www.oneparkfinancial.com/faq">FAQ</a>
          </li>
          <li>
            <a href="https://www.oneparkfinancial.com/careers">Careers</a>
          </li>
        </ul>
      </div>

      <div class="footer-column address-container">
        <h3>Address</h3>
        <ul>
          <li class="text-with-margin">
            <span>
              3390 Mary Street, Suite 216,
              <br />
              Coconut Grove FL 33133
            </span>
          </li>
          <li>
            <span>
              1321 Upland Drive #1690,
              <br />
              Houston TX 77043
            </span>
          </li>
        </ul>
      </div>

      <div class="footer-column contacts-container">
        <h3>Contact Us</h3>
        <div class="questions-line">
          Questions? Speak to a funding expert today.
        </div>
        <div class="phone-number">
          <a href="tel:+18006172020" class="phone-number">1 (800) 617-2020</a>
        </div>
        <div>
          <span>questions@oneparkfinancial.com</span>
          <br />
          <span>www.oneparkfinancial.com</span>
        </div>
      </div>
    </div>

    <div class="footer-logo-container">
      <a href="https://app.oneparkfinancial.com/" class="">
        <img src="assets/logo-white.png" />
      </a>
      <div class="">
        ©{{ 'questionaire.ONE_PARK_FINANCIAL_ALL_RIGHTS_RESERVED' | translate }}
      </div>
      <div class="">
        {{
          'questionaire.FUNDING_OFFERINGS_HAVE_A_TERM_OF_AT_LEAST_60_DAYS'
            | translate
        }}
      </div>
    </div>
  </div>
</div>
