import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ValidationService } from 'src/app/services/validation.service';
import { LocalizationService } from '../../../services/localization.service';

@Component({
  selector: 'app-field-errors',
  templateUrl: './field-errors.component.html',
  styleUrls: ['./field-errors.component.scss'],
})
export class FieldErrorsComponent implements OnInit {
  @Input() name: any;
  @Input() submitted: boolean;

  @Input() control?: AbstractControl;
  @Input() fieldKey?: string;
  @Input() showErrors?: boolean;
  @Input() errorMessage?: string;
  @Input() errorMessages?: { [key: string]: string } = {};
  @Input() useLocalization?: boolean;

  constructor(
    private validationService: ValidationService,
    private localizationService: LocalizationService
  ) {}

  ngOnInit(): void {}

  get show(): boolean {
    return (
      this.showErrors ||
      (this.control != null &&
        this.control.invalid &&
        (this.control.dirty || this.control.touched))
    );
  }

  get errors(): string[] {
    if (!this.control) {
      return [this.errorMessage];
    }

    const fieldErrors = this.control?.errors;

    if (fieldErrors == null) {
      return [];
    }

    const keys = Object.keys(fieldErrors);

    if (!keys.length) {
      return [];
    }

    let result: string[] = [];

    for (const key of keys) {
      if (this.errorMessages[key]) {
        const localizedMessage =
          this.localizationService.getValidationMessageTranslation(
            this.errorMessages[key]
          );
        result.push(localizedMessage ?? this.errorMessages[key]);
      } else {
        const errors = this.validationService.getErrorsMessages(
          this.fieldKey || null,
          key,
          fieldErrors[key]
        );

        result = [...result, ...errors];
      }
    }

    return result;
  }
}
