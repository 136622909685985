import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { OnSubmit } from '../on-submit.interface';
import { Router } from '@angular/router';
import { LeadService } from 'src/app/services/lead.service';
import { Validators as CustomValidators } from '../../../validators';
import { PhoneAsyncValidator } from 'src/app/services/phone-async-validator';
import { CompletedStepsService } from 'src/app/services/completed-steps.service';
import {
  applicationFlowUrl,
  feinLength,
  maxAddressLength,
  maxCityLength,
  maxEmployeesNumber,
  maxIndustryLength,
  maxNameDbaLength,
  maxPhoneLength,
  maxStateLength,
  minEmployeesNumber,
  minPhoneLength,
  onlyNumbersRegex,
  ownersInfoUrl,
  ownersNumberMaxValue,
  ownersNumberMinValue,
  zipCodeLength,
} from '../../../constants';
import { businessInfoFormErrorMessages } from './business-info.formErrorMessages';
import { defaultBusinessInfoOwnersNumber } from '../../../constants/form-default-values';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import statesData from '../../../../assets/states.json';
import { industryValues } from '../constants/industry.const';
import { acceptOnlyNumbersLettersAndSpaceRegex } from '../constants/regex.const';

@Component({
  selector: 'app-business-info',
  templateUrl: './business-info.component.html',
  styleUrls: ['./business-info.component.scss'],
})
export class BusinessInfoComponent implements OnInit, OnSubmit, OnDestroy {
  submitted: boolean = false;
  states: string[] = statesData;
  industries: string[] = industryValues;
  formErrorMessages = businessInfoFormErrorMessages;
  form: FormGroup;

  private requestStateSub: Subscription | null = null;

  get readonly(): boolean {
    return this.leadService.isDocuSignCompleted;
  }

  constructor(
    private fb: FormBuilder,
    private leadService: LeadService,
    private router: Router,
    private completedSteps: CompletedStepsService,
    public translateService: TranslateService
  ) {}

  ngOnDestroy(): void {
    if (this.requestStateSub) {
      this.requestStateSub.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      nameDba: [
        '',
        [
          Validators.required,
          Validators.maxLength(maxNameDbaLength),
          Validators.pattern(acceptOnlyNumbersLettersAndSpaceRegex),
        ],
      ],
      fein: [
        '',
        [
          Validators.required,
          CustomValidators.len(feinLength),
          Validators.pattern(onlyNumbersRegex),
        ],
      ],
      phone: [
        '',
        {
          updateOn: 'blur',
          validators: [
            Validators.required,
            Validators.pattern(onlyNumbersRegex),
            Validators.maxLength(maxPhoneLength),
            Validators.minLength(minPhoneLength),
          ],
          asyncValidators: [new PhoneAsyncValidator(this.leadService)],
        },
      ],
      legalStructure: ['', Validators.required],
      ownersNumber: [
        1,
        [
          Validators.required,
          Validators.min(ownersNumberMinValue),
          Validators.max(ownersNumberMaxValue),
        ],
      ],
      employeesNumber: [
        '',
        [
          Validators.required,
          Validators.min(minEmployeesNumber),
          Validators.max(maxEmployeesNumber),
        ],
      ],
      industry: [
        '',
        [Validators.required, Validators.maxLength(maxIndustryLength)],
      ],
      address: [
        '',
        [Validators.required, Validators.maxLength(maxAddressLength)],
      ],
      city: ['', [Validators.required, Validators.maxLength(maxCityLength)]],
      state: [
        null,
        [Validators.required, Validators.maxLength(maxStateLength)],
      ],
      zipCode: [
        '',
        [
          Validators.required,
          Validators.pattern(onlyNumbersRegex),
          CustomValidators.len(zipCodeLength),
        ],
      ],
    });

    this.requestStateSub = this.leadService.requestState.subscribe((state) => {
      if (this.leadService.isBusinessInfoCompleted && state.id) {
        this.router.navigate([
          applicationFlowUrl,
          this.completedSteps.getFirstNotCompletedStep(),
        ]);
      }
    });

    this.leadService.getBusinessInfo().subscribe((businessInfo) => {
      this.form.patchValue({
        ...businessInfo,
        // legalStructure: businessInfo.legalStructure ?? defaultBusinessInfoLegalStructure,
        ownersNumber:
          businessInfo.ownersNumber ?? defaultBusinessInfoOwnersNumber,
      });
    });
  }

  submit(): void {
    this.submitted = true;

    if (this.form.valid) {
      this.leadService.updateBusinessInfo(this.form.value).subscribe(() => {
        this.leadService.updateApplicationStepsState();
        this.router.navigateByUrl(ownersInfoUrl);
      });
    }
  }

  get nameDba(): FormControl {
    return this.form.get('nameDba') as FormControl;
  }

  get fein(): FormControl {
    return this.form.get('fein') as FormControl;
  }

  get legalStructure(): FormControl {
    return this.form.get('legalStructure') as FormControl;
  }

  get phone(): FormControl {
    return this.form.get('phone') as FormControl;
  }

  get employeesNumber(): FormControl {
    return this.form.get('employeesNumber') as FormControl;
  }

  get industry(): FormControl {
    return this.form.get('industry') as FormControl;
  }

  get address(): FormControl {
    return this.form.get('address') as FormControl;
  }

  get city(): FormControl {
    return this.form.get('city') as FormControl;
  }

  get state(): FormControl {
    return this.form.get('state') as FormControl;
  }

  get zipCode(): FormControl {
    return this.form.get('zipCode') as FormControl;
  }

  getClass(control: FormControl): string {
    if (
      (control.invalid && (control.dirty || control.touched)) ||
      (this.submitted && control.invalid)
    ) {
      return 'is-invalid';
    } else if (this.submitted || control.valid) {
      return 'is-valid';
    } else {
      return '';
    }
  }
}
