import { TranslateService } from '@ngx-translate/core';
import { LanguageNamesEnum } from '../enum/Languages.enum';
import {
  englishLanguageValue,
  spanishLanguageValue,
} from '../constants/languages';

export class LanguageUtil {
  public static getCurrentLanguageName(
    translateService: TranslateService
  ): LanguageNamesEnum {
    switch (String(translateService.currentLang)) {
      case englishLanguageValue:
        return LanguageNamesEnum.English;
      case spanishLanguageValue:
        return LanguageNamesEnum.Spanish;
      default:
        return LanguageNamesEnum.English;
    }
  }
}
