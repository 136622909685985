<div class="upload-button-container">
  <label
    class="btn btn-outline-primary"
    [ngClass]="{ uploaded: uploaded$ | async }"
    [for]="inputId"
  >
    {{
      (uploaded$ | async)
        ? ('upload_button_component.change' | translate)
        : ('upload_button_component.select_file' | translate)
    }}
    <!-- TODO Add localization -->
  </label>
  <input
    [id]="inputId"
    [name]="inputId"
    type="file"
    (change)="fileSelected($event)"
  />
</div>
