export enum OpfEnvironmentName {
  Local = 'local',
  Dev = 'Dev',
  QA = 'QA',
  Staging = 'Staging',
  Prod = 'Prod',
}

export class OpfEnvironment {
  production: boolean;
  apiBaseUrl: string;
  gtmTag: string;
  useGtm: boolean;
  locales: string[];
  defaultLocale: string;
  enableFinicity: boolean;
  apiToken: string;
  globalQuestionnaireFlowUrl: string;
  monitoring: {
    sentry: {
      enabled: boolean;
      debug?: boolean;
      dsn?: string;
      environment: OpfEnvironmentName;
      release: string;
      dist: string;
    };
  };
}
