import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { RequestState } from 'src/app/models/request-state.model';
import { StepsInfo } from './application-flow-interface';
import { FlowIdService } from '../../../services/flow-id.service';

@Component({
  selector: 'app-application-flow-steps',
  templateUrl: './application-flow-steps.component.html',
  styleUrls: ['./application-flow-steps.component.scss'],
})
export class ApplicationFlowStepsComponent implements OnInit {
  requestState?: RequestState;
  activeSteps$: Observable<StepsInfo[]>;

  constructor(
    private activeRoute: ActivatedRoute,
    private flowIdService: FlowIdService  ) {}

  ngOnInit(): void {
    const flowId = this.flowIdService.getFlowId();
    this.activeSteps$ = this.flowIdService.getStepsByFlowId(flowId);
  }


  isRouteActive(step: string): boolean {
    return this.activeRoute.firstChild?.snapshot.data.step === step.toString();
  }

  isRouteCompleted(step: string): boolean {
    return (this.requestState && (this.requestState[step] as any)) || false;
  }
}
