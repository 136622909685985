import { ApplicationFlowStepsEnum } from '../steps-config/application-flow-steps.enum';

export const activatedStepsByFlowId = {
  0: [
    ApplicationFlowStepsEnum.GeneralInfo,
    ApplicationFlowStepsEnum.BusinessInfo,
    ApplicationFlowStepsEnum.OwnersInfo,
    ApplicationFlowStepsEnum.Docusign,
    ApplicationFlowStepsEnum.Reports,
  ],
  1: [
    ApplicationFlowStepsEnum.GeneralInfo,
    ApplicationFlowStepsEnum.BusinessInfo,
    ApplicationFlowStepsEnum.OwnersInfo,
    ApplicationFlowStepsEnum.Docusign,
    ApplicationFlowStepsEnum.Reports,
  ],
  2: [],
  3: [
    ApplicationFlowStepsEnum.GeneralInfo,
    ApplicationFlowStepsEnum.BusinessInfo,
    ApplicationFlowStepsEnum.OwnersInfo,
    ApplicationFlowStepsEnum.Docusign,
    ApplicationFlowStepsEnum.Reports,
  ],
  4: [ApplicationFlowStepsEnum.BusinessInfo],
  10: [
    ApplicationFlowStepsEnum.GeneralInfo,
    ApplicationFlowStepsEnum.BusinessInfo,
    ApplicationFlowStepsEnum.OwnersInfo,
    ApplicationFlowStepsEnum.Docusign,
    ApplicationFlowStepsEnum.Reports,
  ],
  11: [
    ApplicationFlowStepsEnum.GeneralInfo,
    ApplicationFlowStepsEnum.BusinessInfo,
    ApplicationFlowStepsEnum.OwnersInfo,
    ApplicationFlowStepsEnum.Docusign,
    ApplicationFlowStepsEnum.Reports,
  ],
};
