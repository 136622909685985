import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import {
  ApiErrorResponse,
  ErrorResponseType,
} from 'src/app/models/errors/api-error-response.model';
import { LocalizationService } from './localization.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private notifier: NotifierService,
    private localizationService: LocalizationService
  ) {}

  public showInfo(message: string): void {
    this.notifier.notify('info', message);
  }

  public showWarning(
    errorType: ErrorResponseType | null = null,
    message: string | null = null
  ): void {
    if (errorType) {
      this.notifier.notify(
        'warning',
        this.localizationService.getLocalizedErrorType(errorType)
      );
    } else if (message) {
      this.notifier.notify(
        'warning',
        this.localizationService.getLocalizedMessage(message)
      );
    }
  }

  public showError(
    errorType: ErrorResponseType | null = null,
    message: string | null = null
  ): void {
    if (errorType) {
      this.notifier.notify(
        'error',
        this.localizationService.getLocalizedErrorType(errorType)
      );
    } else if (message) {
      this.notifier.notify(
        'error',
        this.localizationService.getLocalizedMessage(message)
      );
    }
  }

  public showErrorResponse(errorResponse: ApiErrorResponse): void {
    if (errorResponse.fieldsValidationErrors == null) {
      this.showError(errorResponse.errorType, errorResponse.message);
      return;
    }
    const fieldsKeys = Object.keys(errorResponse.fieldsValidationErrors);

    const fieldsErrors = fieldsKeys.map(
      (el) =>
        `Field ${el} have next errors:\n ${errorResponse.fieldsValidationErrors[
          el
        ].join('\n')}`
    );

    const message = `${errorResponse.message}:\n${fieldsErrors.join('\n')}`;
    this.notifier.notify('error', message);
  }

  public showSuccess(message: string): void {
    this.notifier.notify('success', message);
  }
}
