import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CreateLeadResponse } from 'src/app/models/responses/create-lead-response.model';
import { FinicityLinkResponse } from 'src/app/models/responses/finicity-link-response.model';
import { PhoneInfo } from 'src/app/models/responses/phone-info.model';
import { Lead } from 'src/app/models/requests/lead.model';
import { LoginUserRequest } from 'src/app/models/requests/login-user-request.model';
import { LoginUserResponse } from 'src/app/models/responses/login-user-response.model';
import { OwnerInfo } from 'src/app/models/owner-info.model';
import { SetPasswordRequest } from 'src/app/models/requests/set-password-request.model';
import { BusinessInfo } from '../models/business-info.model';
import { DocusignLinkModel } from '../models/responses/docusign-link.model';
import { GeneralInfo } from '../models/general-info.model';
import { BaseApiService } from './base-api-service';
import { IsUserExistResponse } from '../models/responses/is-user-exist-response.model';
import { RequestState } from '../models/request-state.model';
import { SigningDocument } from '../models/responses/signing-document.model';
import { ResetPasswordMail } from '../models/requests/reset-password-mail.model';
import { ResetPassword } from '../models/requests/reset-password.model';
import { ContactInfo } from '../models/contact-info.model';
import { map } from 'rxjs/operators';
import { ValidateToken } from '../models/requests/validate-token.model';
import { IsTokenValidResponse } from '../models/responses/is-token-valid-response.model';
import { ChangePassword } from '../models/requests/change-password.model';
import { TranslateService } from '@ngx-translate/core';
import { LanguageUtil } from '../utils/language.util';
import { AccessToken } from '../models/access-token.model';
import { GeneratePasswordMailModel } from '../models/requests/generate-password-mail.model';
import { Router } from '@angular/router';
import { CompletedStepsEnum } from '../enum/CompletedSteps.enum';
import { parseTemplate } from 'url-template';
import { MonitoringService, TransactionType } from './monitoring.service';
import { PlatformAPI, PlatformAPIErrorMessages } from '../constants/platform-api';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class LeadService {
  public requestState: BehaviorSubject<RequestState> = new BehaviorSubject<RequestState>(new RequestState());

  public isLoggedIn: boolean = false;

  public get isDocuSignCompleted(): boolean {
    return this.requestState.value.isDocuSignCompleted;
  }

  public get isGeneralInfoCompleted(): boolean {
    return this.requestState.value.isGeneralInfoCompleted;
  }

  public get isBusinessInfoCompleted(): boolean {
    return this.requestState.value.isBusinessInfoCompleted;
  }

  public get isOwnersInfoCompleted(): boolean {
    return this.requestState.value.isOwnersInfoCompleted;
  }

  public get isReportsCompleted(): boolean {
    return this.requestState.value.isReportsCompleted;
  }

  constructor(
    private baseApiService: BaseApiService,
    private translateService: TranslateService,
    private monitoringService: MonitoringService,
    private router: Router
  ) {}

  public getBusinessInfo(): Observable<BusinessInfo> {
    const span = this.monitoringService.startSpan(TransactionType.ApiCall, `GET ${PlatformAPI.ApplicationBusinessInfo}`);
    return this.baseApiService.getData<BusinessInfo>(PlatformAPI.ApplicationBusinessInfo, true)
      .pipe(map((res) => {
        this.monitoringService.endSpan(span);
        return res;
      }));
  }

  public reloadRequestState(): Observable<RequestState> {
    const span = this.monitoringService.startSpan(TransactionType.ApiCall, `GET ${PlatformAPI.ApplicationState}`);
    return this.baseApiService
      .getData<RequestState>(PlatformAPI.ApplicationState, false)
      .pipe(
        map((result) => {
          this.monitoringService.endSpan(span);
          this.requestState.next(result);
          return result;
        })
      );
  }

  public updateRequestState(newValue: RequestState): void {
    this.requestState.next(newValue);
  }

  public getGeneralInfo(): Observable<GeneralInfo> {
    const span = this.monitoringService.startSpan(TransactionType.ApiCall, `GET ${PlatformAPI.ApplicationGeneralInfo}`);
    return this.baseApiService.getData<GeneralInfo>(PlatformAPI.ApplicationGeneralInfo, true)
      .pipe(map((res) => {
        this.monitoringService.endSpan(span);
        return res;
      }));
  }

  public getOwners(): Observable<OwnerInfo[]> {
    const span = this.monitoringService.startSpan(TransactionType.ApiCall, `GET ${PlatformAPI.ApplicationBusinessOwners}`);
    return this.baseApiService.getData<OwnerInfo[]>(PlatformAPI.ApplicationBusinessOwners, true)
      .pipe(map((res) => {
        this.monitoringService.endSpan(span);
        return res;
      }));
  }

  public getApplicantContact(): Observable<ContactInfo> {
    const span = this.monitoringService.startSpan(TransactionType.ApiCall, `GET ${PlatformAPI.ApplicationApplicantContactInfo}`);
    return this.baseApiService.getData<ContactInfo>(PlatformAPI.ApplicationApplicantContactInfo, true)
      .pipe(map((res) => {
        this.monitoringService.endSpan(span);
        return res;
      }));
  }

  public getDocUsignLink(): Observable<DocusignLinkModel> {
    const span = this.monitoringService.startSpan(TransactionType.ApiCall, `GET ${PlatformAPI.DocusignLink}`);
    return this.baseApiService.getData<DocusignLinkModel>(PlatformAPI.DocusignLink, true)
      .pipe(map((res) => {
        this.monitoringService.endSpan(span);
        return res;
      }));
  }

  public getFinicityLink(): Observable<FinicityLinkResponse> {
    const span = this.monitoringService.startSpan(TransactionType.ApiCall, `GET ${PlatformAPI.FinicityLink}`);
    return this.baseApiService.getData<FinicityLinkResponse>(PlatformAPI.FinicityLink, false)
      .pipe(map((res) => {
        this.monitoringService.endSpan(span);
        return res;
      }));
  }

  public isPhoneValid(phone: string): Observable<boolean> {
    const requestUri = parseTemplate(PlatformAPI.LookupPhoneNumber).expand({phone});

    const span = this.monitoringService.startSpan(TransactionType.ApiCall, `GET ${requestUri}`);

    const noThrow = (response: HttpErrorResponse): boolean => [200, 404].includes(response.status);

    return this.baseApiService
      .getData<PhoneInfo>(requestUri, false, noThrow)
      .pipe(map((res) => {
        this.monitoringService.endSpan(span);
        return res.success;
      }));
  }

  public isEmailValid(
    email: string,
    useLoading: boolean = false
  ): Observable<IsUserExistResponse> {
    const requestUri = parseTemplate(PlatformAPI.UserIsExist).expand({email: encodeURIComponent(email)});

    const span = this.monitoringService.startSpan(TransactionType.ApiCall, `GET ${requestUri}`);

    const noThrow = (response: HttpErrorResponse): boolean => [200, 404].includes(response.status);

    return this.baseApiService
      .getData<IsUserExistResponse>(requestUri, useLoading, noThrow)
      .pipe(map((res) => {
        this.monitoringService.endSpan(span);
        return res;
      }));
  }

  public createLead(
    model: Lead,
    utmQuery: string
  ): Observable<CreateLeadResponse> {
    const currentLanguage = LanguageUtil.getCurrentLanguageName(
      this.translateService
    );

    const relativeUrl = `/leads?language=${currentLanguage}&${utmQuery}`;
    // TODO: move encode utmQuery to base Service

    return this.baseApiService.sendData<Lead, CreateLeadResponse>(
      'post',
      relativeUrl,
      model,
      true
    );
  }

  public setPassword(model: SetPasswordRequest): Observable<void> {
    return this.baseApiService.sendData<SetPasswordRequest, void>(
      'post',
      '/user/setPassword',
      model,
      true
    );
  }

  public getSigningDocumentState(): Observable<SigningDocument> {
    const span = this.monitoringService.startSpan(TransactionType.ApiCall, `GET ${PlatformAPI.DocusignState}`);
    return this.baseApiService.getData<SigningDocument>(PlatformAPI.DocusignState, true)
      .pipe(map((res) => {
        this.monitoringService.endSpan(span);
        return res;
      }));
  }

  public updateGeneralInfo(model: GeneralInfo): Observable<void> {
    const span = this.monitoringService.startSpan(TransactionType.ApiCall, `PUT ${PlatformAPI.ApplicationGeneralInfo}`);
    return this.baseApiService.sendData<GeneralInfo, void>('put', PlatformAPI.ApplicationGeneralInfo, model, true)
      .pipe(map((res) => {
        this.monitoringService.endSpan(span);
        return res;
      }));
  }

  public sendResetPasswordMail(email: string): Observable<void> {
    return this.baseApiService.sendData<ResetPasswordMail, void>(
      'post',
      '/user/send-reset-password-mail',
      { email },
      true
    );
  }

  public resetPassword(token: string, newPassword: string): Observable<void> {
    return this.baseApiService.sendData<ResetPassword, void>(
      'post',
      '/user/reset-password',
      { token, newPassword },
      true
    );
  }

  public updateBusinessInfo(model: BusinessInfo): Observable<void> {
    const span = this.monitoringService.startSpan(TransactionType.ApiCall, `PUT ${PlatformAPI.ApplicationBusinessInfo}`);
    return this.baseApiService.sendData<BusinessInfo, void>('put', PlatformAPI.ApplicationBusinessInfo, model, true)
      .pipe(map((res) => {
        this.monitoringService.endSpan(span);
        return res;
      }));
  }

  public updateOwners(owners: OwnerInfo[]): Observable<void> {
    const span = this.monitoringService.startSpan(TransactionType.ApiCall, `PUT ${PlatformAPI.ApplicationBusinessOwners}`);

    const noThrow = (response: HttpErrorResponse): boolean => response.status === 200 ||
        response.status === 400 && response.error?.message.includes(PlatformAPIErrorMessages.OneOrMoreValidationErrors);

    return this.baseApiService.sendData<OwnerInfo[], void>('put', PlatformAPI.ApplicationBusinessOwners,
      owners.map((el) => ({ ...el})),
      // owners.map((el) => ({ ...el, ownership: el.ownership || 0 })),
      true,
      noThrow
    )
      .pipe(map((res) => {
        this.monitoringService.endSpan(span);
        return res;
      }));
  }

  public login(login: string, password: string): Observable<LoginUserResponse> {
    const model = new LoginUserRequest(login, password);

    return this.baseApiService.sendData<LoginUserRequest, LoginUserResponse>(
      'post',
      '/user/login',
      model,
      true
    );
  }

  public validateResetPasswordToken(
    token: string
  ): Observable<IsTokenValidResponse> {
    return this.baseApiService.sendData<ValidateToken, IsTokenValidResponse>(
      'post',
      '/user/validate-reset-password-token',
      { token },
      true
    );
  }

  public changePassword(newPassword: string): Observable<void> {
    return this.baseApiService.sendData<ChangePassword, void>(
      'post',
      '/user/change-password',
      { newPassword },
      true
    );
  }

  public loginByAccessToken(
    accessToken: string,
    email: string
  ): Observable<LoginUserResponse> {
    return this.baseApiService.sendData<AccessToken, LoginUserResponse>(
      'post',
      '/user/login-by-access-token',
      { token: accessToken, userEmail: email },
      true
    );
  }

  public sendGeneratePasswordEmail(email: string): Observable<void> {
    return this.baseApiService.sendData<GeneratePasswordMailModel, void>(
      'post',
      '/user/generate-password',
      { email },
      true
    );
  }

  public addReEngagementTouchpoint(
    email: string,
    utmQuery: string
  ): Observable<void> {
    return this.baseApiService.sendData<unknown, void>(
      'post',
      `/touchpoint/re-engagement?email=${email}&${utmQuery}`,
      {},
      true
    );
  }

  public updateApplicationStepsState(): void {
    const currentStepUrl = this.router.url.split('/').pop();
    const isCurrentStepCompleted = CompletedStepsEnum[currentStepUrl];
    this.updateRequestState({
      ...this.requestState.value,
      [isCurrentStepCompleted]: true,
    });
  }
}
