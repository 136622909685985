import { ApplicationFlowStepsEnum } from '../steps-config/application-flow-steps.enum';
import { LocalizationKeysEnum } from '../../../enum/LocalizationKeys.enum';

export const activeStepsContent = {
  [ApplicationFlowStepsEnum.GeneralInfo]: {
    url: ApplicationFlowStepsEnum.GeneralInfo,
    stepName: LocalizationKeysEnum.GeneralInformationStepName,
    stepNumber: 1,
  },
  [ApplicationFlowStepsEnum.BusinessInfo]: {
    url: ApplicationFlowStepsEnum.BusinessInfo,
    stepName: LocalizationKeysEnum.BusinessInformationStepName,
    stepNumber: 2,
  },
  [ApplicationFlowStepsEnum.OwnersInfo]: {
    url: ApplicationFlowStepsEnum.OwnersInfo,
    stepName: LocalizationKeysEnum.OwnersInformationStepName,
    stepNumber: 3,
  },
  [ApplicationFlowStepsEnum.Docusign]: {
    url: ApplicationFlowStepsEnum.Docusign,
    stepName: LocalizationKeysEnum.DocusignStepName,
    stepNumber: 4,
  },
  [ApplicationFlowStepsEnum.Reports]: {
    url: ApplicationFlowStepsEnum.Reports,
    stepName: LocalizationKeysEnum.ReportsStepName,
    stepNumber: 5,
  },
  // [ApplicationFlowStepsEnum.Finicity]: {
  //   url: ApplicationFlowStepsEnum.Reports,
  //   stepName: LocalizationKeysEnum.FinicityStepName,
  //   stepNumber: 5,
  // },
};
