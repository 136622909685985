import { Injectable, Inject, EventEmitter } from '@angular/core';
import { THEMES, ACTIVE_THEME, Theme } from './symbols';

@Injectable()
export class ThemeService {
  themeChange = new EventEmitter<Theme>();

  constructor(
    @Inject(THEMES) public themes: Theme[],
    @Inject(ACTIVE_THEME) public theme: string
  ) {}

  // getTheme(name: string): Theme {
  getTheme(): Theme {
    const queryParams = new URLSearchParams(window.location.search);
    let brandId = queryParams.get('brandId');
    localStorage.setItem('brandId', brandId);
    if(!brandId) {
      brandId = '0';
    }
    const theme = this.themes.find((t) => t.name === brandId);
    if (!theme) {
      throw new Error(`Theme not found: '${brandId}'`);
    }
    return theme;
  }

  getActiveTheme(): Theme {
    // return this.getTheme(this.theme);
    return this.getTheme();
  }

  getProperty(propName: string): Theme {
    return this.getActiveTheme().properties[propName];
  }

  setTheme(name: string): void {
    this.theme = name;
    this.themeChange.emit(this.getActiveTheme());
  }

  registerTheme(theme: Theme): void {
    this.themes.push(theme);
  }

  updateTheme(name: string, properties: { [key: string]: string }): void {
    const theme = this.getTheme();
    theme.properties = {
      ...theme.properties,
      ...properties,
    };

    if (name === this.theme) {
      this.themeChange.emit(theme);
    }
  }
}
