<div class="container-fluid container-xl">

  <h1 class="visually-hidden">Business Information</h1>

  <div id="head-line" class="row text-center justify-content-center">
    <div class="col-12">
      <h4>
        {{ 'business_page_form.business_header' | translate }}
      </h4>
      <!-- <p>Now, let's finish your application online to speed up the process</p> -->
    </div>
  </div>

  <div class="row justify-content-center" [formGroup]="form">
    <div class="col-12 col-lg-10">
      <hr class="mb-5" />

      <form class="row g-4 needs-validation" novalidate (ngSubmit)="submit()">
        <div class="col-12 col-md-6">
          <label for="nameDba" class="form-label">{{
            'business_page_form.nameDba' | translate
          }}</label>
          <input
            type="text"
            id="nameDba"
            value=""
            class="form-control form-control-lg"
            formControlName="nameDba"
            required
            [ngClass]="getClass(nameDba)"
          />
          <div
            *ngIf="
              (nameDba.valid && (nameDba.dirty || nameDba.touched)) ||
              (submitted && nameDba.valid)
            "
            class="valid-feedback"
          >
            Looks good!
          </div>

          <div *ngIf="nameDba.invalid && (nameDba.dirty || nameDba.touched) || (submitted && nameDba.invalid)"
            class="invalid-feeback">
            <app-field-errors
              [control]="nameDba"
              [errorMessages]="formErrorMessages.nameDba"
              [showErrors]="submitted"
            ></app-field-errors>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <label for="fein" class="form-label">{{
            'business_page_form.fein' | translate
          }}</label>
          <input
            class="form-control form-control-lg"
            id="fein"
            value=""
            formControlName="fein"
            type="tel"
            mask="000000000"
            required
            [ngClass]="getClass(fein)"
          />
          <div
            *ngIf="
              (fein.valid && (fein.dirty || fein.touched)) ||
              (submitted && fein.valid)
            "
            class="valid-feedback"
          >
            Looks good!
          </div>

          <div *ngIf="fein.invalid && (fein.dirty || fein.touched) || (submitted && fein.invalid)"
            class="invalid-feeback">
            <app-field-errors
              [control]="fein"
              [errorMessages]="formErrorMessages.fein"
              [showErrors]="submitted"
            ></app-field-errors>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <label for="businessPhone" class="form-label">{{
            'business_page_form.phone_number' | translate
          }}</label>
          <input
            type="tel"
            class="form-control form-control-lg"
            id="businessPhone"
            value=""
            formControlName="phone"
            autocomplete="tel"
            mask="(000) 000 0000 0"
            [dropSpecialCharacters]="true"
            maxlength="16"
            required
            [ngClass]="getClass(phone)"
          />
          <div
            *ngIf="
              (phone.valid && (phone.dirty || phone.touched)) ||
              (submitted && phone.valid)
            "
            class="valid-feedback"
          >
            Looks good!
          </div>
          <div *ngIf="phone.invalid && (phone.dirty || phone.touched) || (submitted && phone.invalid)"
            class="invalid-feeback">
            <app-field-errors
              [control]="phone"
              [errorMessages]="formErrorMessages.phone"
              [showErrors]="submitted"
            ></app-field-errors>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <div class="bg-white border border-2 p-3">
            <p>{{ 'business_page_form.legal_structure' | translate }}</p>
            <fieldset name="select business type">
              <legend class="d-none">LLC</legend>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" id="llc" name="legalStructure" formControlName="legalStructure" value="Llc"  />
                <label class="form-check-label" for="llc">LLC</label>
              </div>
              <legend class="d-none">INC</legend>
              <div class="form-check form-check-inline">
                <input class="form-check-input" id="inc" type="radio" name="legalStructure" formControlName="legalStructure" value="Inc"  />
                <label class="form-check-label" for="inc">INC</label>
              </div>
              <legend class="d-none">DBA</legend>
              <div class="form-check form-check-inline">
                <input class="form-check-input" id="dba" type="radio" name="legalStructure" formControlName="legalStructure" value="Dba"  />
                <label class="form-check-label" for="dba">DBA</label>
              </div>
            </fieldset>
          </div>
        <div *ngIf="legalStructure.invalid || (submitted && legalStructure.invalid)"
          class="invalid-feeback">
          <app-field-errors
            [control]="legalStructure"
            [errorMessages]="formErrorMessages.legalStructure"
            [showErrors]="submitted"
          ></app-field-errors>
        </div>
        </div>

        <div class="col-12 col-md-3">
        <label for="owners" class="form-label">{{'business_page_form.owners' | translate}}</label>
          <select
            id="owners"
            class="form-select form-select-lg"
            formControlName="ownersNumber"
          >
            <option [ngValue]="1" selected>1</option>
            <option [ngValue]="2">2</option>
          </select>
        </div>
        <div class="col-12 col-md-3">
          <label for="employees" class="form-label">{{
            'business_page_form.employees' | translate
          }}</label>
          <input
            type="tel"
            class="form-control form-control-lg"
            id="employees"
            value=""
            formControlName="employeesNumber"
            mask="000"
            required
            [ngClass]="getClass(phone)"
          />
          <div
            *ngIf="
              (employeesNumber.valid &&
                (employeesNumber.dirty || employeesNumber.touched)) ||
              (submitted && employeesNumber.valid)
            "
            class="valid-feedback"
          >
            Looks good!
          </div>
          <div
            *ngIf="employeesNumber.invalid && (employeesNumber.dirty || employeesNumber.touched) || (submitted && employeesNumber.invalid)"
            class="invalid-feeback">
            <app-field-errors
              [control]="employeesNumber"
              [errorMessages]="formErrorMessages.employeesNumber"
              [showErrors]="submitted"
            ></app-field-errors>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <label for="industry" class="form-label">{{
            'business_page_form.industry' | translate
          }}</label>
          <select
            class="form-select form-select-lg"
            id="industry"
            value=""
            formControlName="industry"
            required
            [ngClass]="getClass(industry)"
          >
            <option *ngFor="let industry of industries" [ngValue]="industry">
              {{ industry }}
            </option>
          </select>
          <div
            *ngIf="
              (industry.valid && (industry.dirty || industry.touched)) ||
              (submitted && industry.valid)
            "
            class="valid-feedback"
          >
            Looks good!
          </div>
          <div *ngIf="industry.invalid && (industry.dirty || industry.touched) || (submitted && industry.invalid)"
            class="invalid-feeback">
            <app-field-errors
              [control]="industry"
              [errorMessages]="formErrorMessages.industry"
              [showErrors]="submitted"
            ></app-field-errors>
          </div>
        </div>
        <div class="col-12">
          <label for="businessAddress" class="form-label">{{
            'business_page_form.address' | translate
          }}</label>
          <input
            class="form-control form-control-lg"
            id="businessAddress"
            value=""
            formControlName="address"
            autocomplete="shipping street-address"
            required
            [ngClass]="getClass(address)"
          />
          <div
            *ngIf="
              (address.valid && (address.dirty || address.touched)) ||
              (submitted && address)
            "
            class="valid-feedback"
          >
            Looks good!
          </div>
          <div *ngIf="address.invalid && (address.dirty || address.touched) || (submitted && address.invalid)"
            class="invalid-feeback">
            <app-field-errors
              [control]="address"
              [errorMessages]="formErrorMessages.address"
              [showErrors]="submitted"
            ></app-field-errors>
          </div>
        </div>

        <div class="col-12 col-md-4">
          <label for="city" class="form-label">{{
            'business_page_form.city' | translate
          }}</label>
          <input
            class="form-control form-control-lg"
            id="city"
            value=""
            formControlName="city"
            autocomplete="shipping locality"
            required
            [ngClass]="getClass(city)"
          />
          <div
            *ngIf="
              (city.valid && (city.dirty || city.touched)) ||
              (submitted && city)
            "
            class="valid-feedback"
          >
          Looks good!
          </div>
          <div *ngIf="city.invalid && (city.dirty || city.touched) || (submitted && city.invalid)"
            class="invalid-feeback">
            <app-field-errors
              [control]="city"
              [errorMessages]="formErrorMessages.city"
              [showErrors]="submitted"
            ></app-field-errors>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <label for="state" class="form-label">{{
            'business_page_form.state' | translate
          }}</label>
          <select
            class="form-select form-select-lg"
            formControlName="state"
            autocomplete="shipping region"
          >
            <option [ngValue]="null" disabled selected>
              {{ 'validation_messages.select_a_state' | translate }}
            </option>
            <option *ngFor="let state of states" [ngValue]="state">
              {{ state }}
            </option>
          </select>
          <div *ngIf="state.invalid && (state.dirty || state.touched) || (submitted && state.invalid)"
            class="invalid-feeback">
            <app-field-errors
              [control]="state"
              [errorMessages]="formErrorMessages.state"
              [showErrors]="submitted"
            ></app-field-errors>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <label for="zipCode" class="form-label">{{
            'business_page_form.zip_code' | translate
          }}</label>
          <input
            class="form-control form-control-lg"
            value=""
            id="zipCode"
            formControlName="zipCode"
            type="tel"
            autocomplete="shipping postal-code"
            mask="00000"
            required
            [ngClass]="getClass(zipCode)"
          />
          <div
            *ngIf="
              (zipCode.valid && (zipCode.dirty || zipCode.touched)) ||
              (submitted && zipCode)
            "
            class="valid-feedback"
          >
            Looks good!
          </div>
          <div *ngIf="zipCode.invalid && (zipCode.dirty || zipCode.touched) || (submitted && zipCode.invalid)"
            class="invalid-feeback">
            <app-field-errors
              [control]="zipCode"
              [errorMessages]="formErrorMessages.zipCode"
              [showErrors]="submitted"
            ></app-field-errors>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>