export enum FormErrorsEnum {
  Required = 'required',
  IncorrectLength = 'incorrectLength',
  InvalidPhone = 'invalidPhone',
  Email = 'email',
  Max = 'max',
  Min = 'min',
  MaxLength = 'maxlength',
  Pattern = 'pattern',
  MinLength = 'minlength',
  Password = 'password',
  MinAgeRestriction = 'minAgeRestriction',
  MaxAgeRestriction = 'maxAgeRestriction',
}
