<nav class="navbar navbar-dark shadow bg-primary sticky-top">
  <div class="container-fluid container-xl">
      <div class="navbar-brand">
          <img src="/assets/logo-white-icon-wordmark.svg" width="125" class="me-3 d-inline-block d-sm-none"
              alt="One Park Financial">
          <img src="/assets/logo-white-icon-wordmark.svg" width="200" class="me-3 d-none d-sm-block"
              alt="One Park Financial">
      </div>
      <div class="d-flex">
          <div class="btn disabled">
              <i class="fal fa-globe text-white fs-4 opacity-100" role="img"></i>
          </div>
          <fieldset name="language selector">
            <div class="btn-group " role="group" aria-label="Choose Language">
                <legend class="d-none">Select English</legend>
                <input type="radio" class="btn-check" name="langSwitch" id="englishLang" autocomplete="off" [checked]="!isSpanish" (click)="switchLanguage('en')">
                <label class="btn btn-outline-light" for="englishLang">EN</label>

                <legend class="d-none">Select Spanish</legend>
                <input type="radio" class="btn-check" name="langSwitch" id="spanishLang" autocomplete="off" [checked]="isSpanish" (click)="switchLanguage('es')">
                <label class="btn btn-outline-light" for="spanishLang">ES</label>
            </div>
          </fieldset>
      </div>
  </div>
</nav>
