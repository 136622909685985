import {
  AfterContentChecked,
  Component,
  OnInit,
  ViewEncapsulation,
  Renderer2,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  Router,
} from '@angular/router';
import { environment } from 'src/environments/environment';
import { LoadingService } from './services/loading.service';
import { UtmService } from './services/utm-service';
import { SmartGoogleTagManagerService } from './services/smart-google-tag-manager.service';
import { FooterEnabledPages } from './components/application-flow/constants/footer-enabled.const';
import { ChangeDetectorRef } from '@angular/core';
import { ThemeService } from './theme/theme.service';
import { ScriptService } from './services/script.service';
import { inCollectionsPageUrl } from './constants/url.const';
import { FlowIdService } from './services/flow-id.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, AfterContentChecked {
  isLoading = this.loadingService.isLoading();
  isFooterVisible: boolean;

  constructor(
    private loadingService: LoadingService,
    private utmService: UtmService,
    private gtmService: SmartGoogleTagManagerService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private themeService: ThemeService,
    private renderer: Renderer2,
    private scriptService: ScriptService,
    private flowIdService: FlowIdService
  ) {}

  ngAfterContentChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit(): void {
    const queryParams = new URLSearchParams(window.location.search);
    const flowId = queryParams.get('flowId');
    this.flowIdService.setFlowId(flowId);

    this.router.urlUpdateStrategy = 'eager';

    if (flowId === '2') {
      this.router.navigate([inCollectionsPageUrl]);
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        this.isFooterVisible = this.getFooterVisibility(this.router.url);
      }
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      this.utmService.storeUtm(params);
    });

    if (environment.gtmTag) {
      this.router.events.forEach((item) => {
        if (item instanceof NavigationEnd) {
          const gtmTag = {
            event: 'pageview',
            path: (item as NavigationEnd).url,
          };
          this.gtmService.pushTag(gtmTag);
        }
      });
    }

    // this.setTheme();
  }

  private getFooterVisibility(url: string): boolean {
    return FooterEnabledPages.some((page) => url.includes(page));
  }

  setTheme(): void {
    const active = this.themeService.getActiveTheme();
    const script = this.scriptService.getScriptBasedOnEnvironment(active);
    this.scriptService.loadHeaderLink(this.renderer, script);
  }
}
